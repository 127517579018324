import axios from "axios";
import { store } from "../store";
import { authActions } from "../store/reducers/authReducer";
import { feedbackActions } from "../store/reducers/feedbackReducer";

const baseURL = process.env.REACT_APP_API_BASEURL;

// create axios instance
const instance = axios.create({
  baseURL,
});

//request interceptor to add the auth token header to requests
instance.interceptors.request.use(
  (config) => {
    // checking if the access token exist in the localStorage
    const accessToken = store.getState().auth.token;
    config.headers = {
      "Content-Type": config.headers["Content-Type"]
        ? config.headers["Content-Type"]
        : "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    // config.withCredentials = true;
    return config;
  },
  (error) => {
    if (error.response) {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: error.response.data?.msg || "Something went wrong, please contact support!",
        }),
      );

      console.error(error.response);
    } else if (error.request) {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: "Could not fetch data from server! Please check your internet connection.",
        }),
      );
    } else {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: "Unknown Error Occured",
        }),
      );
    }
    return Promise.reject(error);
  },
);

// response interceptor to refresh token on receiving token expired error
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      // logging user out
      store.dispatch(authActions.LOGOUT());
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: error.response.data?.msg || "Session Expired",
        }),
      );
      return;
    }
    if (error.response.status === 500) {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: error.response.data?.msg || "Server did not respond",
        }),
      );
    }
    if (error.response.status === 404) {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: "Unkown request",
        }),
      );
    }
    if (error.response) {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: error.response.data?.msg || "Something went wrong, please contact support!",
        }),
      );

      console.error(error.response);
    } else if (error.request) {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: "Could not fetch data from server! Please check your internet connection.",
        }),
      );
    } else {
      store.dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: "Unknown Error Occured",
        }),
      );
    }
    return Promise.reject(error);
  },
);

export default instance;
