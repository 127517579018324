import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Alert,
  Stack,
  Input,
  colors,
  Button,
  TextField,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import { styles } from "../../../constants/styles";
import Page from "../../../components/global/Page";
import FormButton from "../../../components/global/FormButton";
import AlertModal from "../../../components/global/AlertModal";
import FormModal from "../../../components/dashboard/FormModal";
import NoDataOverlay from "../../../components/global/NoDataOverlay";
import { Add, Delete, Edit, PhotoCamera, Visibility, VisibilityOff } from "@mui/icons-material";

import {
  getAllTestimonials,
  createNewTestimonial,
  updateTestimonialImage,
  updateExistingTestimonial,
  deleteExistingTestimonial,
} from "../../../api/testimonials";
import {
  testimonialValidationSchema,
  deleteTestimonialValidationSchema,
} from "../../../validation/testimonial";
import { feedbackActions } from "../../../store/reducers/feedbackReducer";
import { testimonialAction } from "../../../store/reducers/testimonialReducer";

const Testimonial = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [formData, setFormData] = useState();
  const [activeForm, setActiveForm] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContetnt, setAlertContent] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const testimonials = useSelector((state) => state.testimonial.testimonials);

  useEffect(() => {
    (async () => {
      setDataLoading(true);
      const res = await getAllTestimonials();
      dispatch(testimonialAction.setTestimonial(res));
      setDataLoading(false);
    })();
  }, []);

  const handleResolver = () => {
    switch (activeForm) {
      case "CREATE":
        return testimonialValidationSchema;
      case "UPDATE":
        return testimonialValidationSchema;
      case "DELETE":
        return deleteTestimonialValidationSchema;
      default:
        return null;
    }
  };

  const resolver = yupResolver(handleResolver());
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const url = useWatch({ control, name: "url" });
  const name = useWatch({ control, name: "name" });
  const message = useWatch({ control, name: "message" });
  const password = useWatch({ control, name: "password" });
  const designation = useWatch({ control, name: "designation" });
  const organization = useWatch({ control, name: "organization" });
  const testimonialImage = useWatch({ control, name: "testimonialImage" });

  const handleAddModalClose = () => {
    setAddModalOpen(false);
    reset({
      url: "",
      name: "",
      message: "",
      designation: "",
      organization: "",
      testimonialImage: [],
    });
  };

  const onCreateSubmit = (data) => {
    setFormData(data);
    setAlertContent(`Are you sure you want to add ${data?.name}'s testimonial?`);
    setAlertOpen(true);
  };

  const createTestimonial = async () => {
    setFormLoading(true);
    const { name, message, designation, organization, url, testimonialImage } = formData;
    const response = await createNewTestimonial({
      name,
      message,
      designation,
      organization,
      url: url || "",
      testimonialImage: testimonialImage?.[0],
    });
    if (response) {
      dispatch(testimonialAction.addTestimonial(response));
      dispatch(
        feedbackActions.NOTIFY({
          status: "success",
          message: `${name}'s testimonial is added to the list`,
        }),
      );
      handleAddModalClose();
    } else {
      dispatch(feedbackActions.NOTIFY({ status: "error", message: "Failed to add testimonial" }));
    }
    setFormLoading(false);
  };

  const handleRowUpdate = (row) => {
    setRowData(row);
    setValue("url", row.url);
    setValue("name", row.name);
    setValue("message", row.message);
    setValue("designation", row.designation);
    setValue("organization", row.organization);
    setActiveForm("UPDATE");
    setUpdateModalOpen(true);
  };

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
    reset({
      url: "",
      name: "",
      message: "",
      designation: "",
      organization: "",
      testimonialImage: [],
    });
  };

  const detectUpdate = () => {
    let flag = true;
    if (
      rowData?.url !== url ||
      rowData?.name !== name ||
      rowData?.message !== message ||
      rowData?.designation !== designation ||
      rowData?.organization !== organization ||
      testimonialImage?.[0]
    ) {
      flag = false;
    }

    return flag;
  };

  const onUpdateSubmit = (data) => {
    setFormData(data);
    setAlertContent(`Are you sure you want to update ${data?.name}'s testimonial?`);
    setAlertOpen(true);
  };

  const updateTestimonial = async () => {
    setFormLoading(true);
    const { name, message, designation, organization, url, testimonialImage } = formData;
    if (testimonialImage?.[0]) {
      await updateTestimonialImage({ testimonialImage: testimonialImage[0], uid: rowData?.uid });
    }
    const response = await updateExistingTestimonial(
      { name, message, designation, organization, url },
      rowData?.uid,
    );
    if (response) {
      dispatch(testimonialAction.updateTestimonial(response));
      dispatch(
        feedbackActions.NOTIFY({ status: "success", message: `${name}'s testimonial updated` }),
      );
      handleUpdateModalClose();
    } else {
      dispatch(
        feedbackActions.NOTIFY({ status: "error", message: "Failed to update testimonial" }),
      );
    }
    setFormLoading(false);
  };

  const handleRowDelete = (row) => {
    setFormData({ deleteList: [row.uid] });
    setActiveForm("DELETE");
    setAlertContent(
      `Are you sure you want to delete ${row.name}'s testimonial? You can not revert this action!`,
    );
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    reset({ password: "" });
  };

  const onDeleteSubmit = (data) => {
    setFormData({ ...formData, password: data.password });
    setAlertOpen(true);
  };

  const deleteTestimonial = async () => {
    setFormLoading(true);
    const response = await deleteExistingTestimonial(formData);
    if (response) {
      dispatch(testimonialAction.removeTestimonial(response));
      dispatch(
        feedbackActions.NOTIFY({
          status: "success",
          message: "Testimonial was removed from the list",
        }),
      );
      handleDeleteModalClose();
    } else {
      dispatch(
        feedbackActions.NOTIFY({ status: "error", message: "Failed to delete testimonial" }),
      );
    }
    setFormLoading(false);
  };

  const columns = [
    {
      width: 100,
      field: "image",
      headerName: "Photo",
      renderCell: (params) => (
        <Box width="30px">
          <img src={params?.value || "/assets/images/avatar.png"} width="100%" />
        </Box>
      ),
    },
    {
      minWidth: 140,
      field: "name",
      headerName: "Name",
    },
    {
      minWidth: 140,
      field: "designation",
      headerName: "Designation",
    },
    {
      minWidth: 140,
      field: "organization",
      headerName: "Organization",
    },
    {
      flex: 1,
      hide: true,
      minWidth: 250,
      field: "message",
      headerName: "Message",
    },
    {
      width: 110,
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton color="success" onClick={() => handleRowUpdate(params.row)}>
            <Edit />
          </IconButton>
          <IconButton color="error" onClick={() => handleRowDelete(params.row)}>
            <Delete />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const handleAlertCallback = () => {
    switch (activeForm) {
      case "CREATE":
        return createTestimonial;
      case "UPDATE":
        return updateTestimonial;
      case "DELETE":
        return deleteTestimonial;
      default:
        return null;
    }
  };

  return (
    <Page>
      {/* ALERT MODAL */}
      <AlertModal
        title="Confirm"
        isOpen={alertOpen}
        content={alertContetnt}
        onClose={() => setAlertOpen(false)}
        onResolveClick={handleAlertCallback()}
      />

      {/* ADD FORM MODAL */}
      <FormModal
        isOpen={addModalOpen}
        title="Add New Testimonial"
        onClose={handleAddModalClose}
        onSubmitCallback={handleSubmit(onCreateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <FormControl error={errors?.testimonialImage}>
              <label htmlFor="testimonial-image-file">
                <Input
                  type="file"
                  id="testimonial-image-file"
                  sx={{ display: "none" }}
                  {...register("testimonialImage")}
                />
                {testimonialImage?.[0] ? (
                  <span>
                    <img
                      width="100px"
                      style={{ cursor: "pointer" }}
                      src={URL.createObjectURL(testimonialImage[0])}
                    />
                  </span>
                ) : (
                  <Button
                    size="small"
                    component="span"
                    variant="outlined"
                    startIcon={<PhotoCamera />}
                    color={errors?.testimonialImage ? "error" : "primary"}>
                    Upload Photo
                  </Button>
                )}
              </label>
            </FormControl>
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.testimonialImage?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Name"
              error={errors?.name}
              {...register("name")}
              placeholder="name of the person testifying"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.name?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Designation"
              error={errors?.designation}
              {...register("designation")}
              placeholder="designation of the person testifying"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.designation?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Organization"
              error={errors?.organization}
              {...register("organization")}
              placeholder="organization of the person testifying"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.organization?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Important Link"
              error={errors?.url}
              {...register("url")}
              placeholder="external link for the client/organization"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.url?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              rows={4}
              multiline
              label="Message"
              error={errors?.message}
              {...register("message")}
              placeholder="write short message as a testimonial"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.message?.message}
            </FormHelperText>
          </Stack>

          <FormButton type="submit" isLoading={formLoading}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* UPDATE FORM MODAL */}
      <FormModal
        isOpen={updateModalOpen}
        title="Update Testimonial"
        onClose={handleUpdateModalClose}
        onSubmitCallback={handleSubmit(onUpdateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <FormControl error={errors?.testimonialImage} sx={{ width: "fit-content" }}>
              <label htmlFor="service-icon-file">
                <Input
                  type="file"
                  id="service-icon-file"
                  sx={{ display: "none" }}
                  {...register("testimonialImage")}
                />
                {!testimonialImage?.[0] && rowData?.image ? (
                  <span>
                    <img src={rowData.image} width="100px" style={{ cursor: "pointer" }} />
                  </span>
                ) : testimonialImage?.[0] ? (
                  <span>
                    <img
                      width="100px"
                      style={{ cursor: "pointer" }}
                      src={URL.createObjectURL(testimonialImage[0])}
                    />
                  </span>
                ) : (
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<PhotoCamera />}
                    color={errors?.testimonialImage ? "error" : "primary"}>
                    Upload Photo
                  </Button>
                )}
              </label>
            </FormControl>
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.testimonialImage?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Name"
              error={errors?.name}
              {...register("name")}
              placeholder="name of the person testifying"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.name?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Designation"
              error={errors?.designation}
              {...register("designation")}
              placeholder="designation of the person testifying"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.designation?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Organization"
              error={errors?.organization}
              {...register("organization")}
              placeholder="organization of the person testifying"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.organization?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Important Link"
              error={errors?.url}
              {...register("url")}
              placeholder="external link for the client/organization"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.url?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              rows={4}
              multiline
              label="Message"
              error={errors?.message}
              {...register("message")}
              placeholder="write short message as a testimonial"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.message?.message}
            </FormHelperText>
          </Stack>

          <FormButton type="submit" isLoading={formLoading} isDisabled={detectUpdate()}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* DELETE FORM MODAL */}
      <FormModal
        isOpen={deleteModalOpen}
        title="Delete Testimonial"
        onClose={handleDeleteModalClose}
        onSubmitCallback={handleSubmit(onDeleteSubmit)}>
        <Stack spacing={2}>
          <Alert severity="warning">Enter your password to confirm this action.</Alert>
          <Stack>
            <TextField
              name="password"
              label="Password *"
              variant="standard"
              autoComplete="current-password"
              id="standard-password-input-required"
              type={password?.length > 0 ? (showPassword ? "text" : "password") : "text"}
              error={errors?.password}
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.password?.message}
            </FormHelperText>
          </Stack>
          <FormButton
            type="submit"
            isLoading={formLoading}
            isDisabled={!password || password?.length < 8}>
            Delete
          </FormButton>
        </Stack>
      </FormModal>

      {/* PARENT COMPONENT */}
      <Stack direction="row" alignItems="center" flexWrap="wrap" justifyContent="space-between">
        <Typography {...styles.text.adminPageHeader}>Testimonials</Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            setActiveForm("CREATE");
            setAddModalOpen(true);
          }}>
          Add New
        </Button>
      </Stack>
      <Box height="400px" my={3}>
        <DataGrid
          columns={columns}
          rows={testimonials}
          loading={dataLoading}
          components={{ NoResultsOverlay: NoDataOverlay, NoRowsOverlay: NoDataOverlay }}
        />
      </Box>
    </Page>
  );
};

export default Testimonial;
