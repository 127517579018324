import React from "react";
import { Email, LinkedIn, Twitter } from "@mui/icons-material";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import { pages, RSQ_CONTACT_EMAIL, RSQ_LINKEDIN, RSQ_TWITTER } from "../constants/links";

const Foooter = () => {
  return (
    <Box mt={10} boxShadow={5} bgcolor="#222222" boxSizing="border-box" py={3}>
      <Container>
        <Stack container direction="row" justifyContent="space-between" flexWrap="wrap">
          <Box m={2} minWidth="220px">
            <Stack spacing={2}>
              <Box
                width="45px"
                height="45px"
                component="img"
                borderRadius={1}
                src="/assets/images/rsq-logo.png"
              />
              <Typography color="white" fontSize="1.6rem" fontWeight="900">
                R Squared
              </Typography>
              <Typography width="210px" fontSize="0.8rem" color="white">
                A team of data driven business decision makers, data scientists and engineers
                working as strategists, who will look into your overall business model and work.
              </Typography>
            </Stack>
          </Box>
          <Box m={2} minWidth="220px">
            <Stack spacing={2} alignItems="flex-start">
              <Typography fontSize="1.4rem" fontWeight="700">
                Site Map
              </Typography>
              <Stack spacing={1}>
                {pages.map((page) => (
                  <Typography
                    component="a"
                    key={page.anchor}
                    href={page.anchor}
                    className="footer-links">
                    {page.label}
                  </Typography>
                ))}
                <Typography href="/blog" component="a" className="footer-links">
                  All blogs
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box m={2} minWidth="220px">
            <Typography fontSize="1.4rem" fontWeight="700">
              Social Links
            </Typography>
            <Stack direction="row" spacing={2} mt={1}>
              <a href={`mailto:${RSQ_CONTACT_EMAIL}`} target="_blank" rel="noreferrer">
                <IconButton>
                  <Email sx={{ width: "30px", height: "30px", fill: "#fff" }} />
                </IconButton>
              </a>
              <a href={RSQ_TWITTER} target="_blank" rel="noreferrer">
                <IconButton>
                  <Twitter sx={{ width: "30px", height: "30px", fill: "#fff" }} />
                </IconButton>
              </a>
              <a href={RSQ_LINKEDIN} target="_blank" rel="noreferrer">
                <IconButton>
                  <LinkedIn sx={{ width: "30px", height: "30px", fill: "#fff" }} />
                </IconButton>
              </a>
            </Stack>
            <Typography pt={4} fontSize="1rem">
              Copyright © 2022 R-Squared All Rights Reserved
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Foooter;
