import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import feedbackReducer from "./feedbackReducer";
import serviceReducer from "./serviceReducer";
import testimonialReducer from "./testimonialReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
  auth: authReducer,
  feedback: feedbackReducer,
  service: serviceReducer,
  testimonial: testimonialReducer,
  user: userReducer,
});

export default reducers;
