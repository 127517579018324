import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Unauthorized from "../components/dashboard/Unauthorized";
import { ADMIN_LOGIN } from "../constants/routerUrls";

const Protected = (props) => {
  const user = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuth);

  if (!isAuth) {
    return <Navigate to={ADMIN_LOGIN} replace={true} />;
  }

  if (!props?.[user?.role]) {
    return <Unauthorized />;
  }

  return props.children;
};

export default Protected;
