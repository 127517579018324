import Home from "../pages/public/Home";
import AdminLogin from "../pages/admin/auth/AdminLogin";
import AdminRoot from "../pages/admin/AdminRoot";
import * as routerUrls from "./routerUrls";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminForgotPassword from "../pages/admin/auth/AdminForgotPassword";
import BlogList from "../pages/admin/blogs/BlogList";
import AddNewBlog from "../pages/admin/blogs/AddNewBlog";
import ServiceList from "../pages/admin/services/ServiceList";
import Testimonial from "../pages/admin/testimonials/Testimonial";
import Blog from "../pages/public/Blog";
import UpdateBlog from "../pages/admin/blogs/UpdateBlog";
import User from "../pages/admin/users/User";
import { adminRoles } from "./auth";
import AdminResetPassword from "../pages/admin/auth/AdminResetPassword";
import AllBlogs from "../pages/public/AllBlogs";
import Unsubscribe from "../pages/public/Unsubscribe";

export const routes = [
  /* public routes */
  {
    label: "Home",
    path: routerUrls.ROOT,
    Element: Home,
    isProtected: false,
    isPublic: true,
    index: true,
    seo: {
      title: "R-SQUARED",
      description: "Official site of R-Squared Solutions",
    },
  },
  {
    label: "Blog",
    path: routerUrls.BLOG + "/:slug",
    Element: Blog,
    isProtected: false,
    isPublic: true,
    index: true,
    seo: null,
  },
  {
    label: "Unsubscribe",
    path: routerUrls.UNSUBSCRIBE + "/:uid",
    Element: Unsubscribe,
    isProtected: false,
    isPublic: true,
    index: true,
    seo: {
      title: "R-SQUARED",
      description: "Official site of R-Squared Solutions",
    },
  },
  {
    label: "Blog",
    path: routerUrls.BLOG,
    Element: AllBlogs,
    isProtected: false,
    isPublic: true,
    index: true,
    seo: {
      title: "R-SQUARED",
      description: "Official site of R-Squared Solutions",
    },
  },

  /* admin auth routes */
  {
    label: "Admin",
    path: routerUrls.ADMIN_ROOT,
    Element: AdminRoot,
    isProtected: false,
    isPublic: false,
    index: true,
    seo: null,
  },
  {
    label: "Admin Login",
    path: routerUrls.ADMIN_LOGIN,
    Element: AdminLogin,
    isProtected: false,
    isPublic: false,
    index: true,
    seo: null,
  },
  {
    label: "Forgot Password",
    path: routerUrls.ADMIN_FORGOT_PASSWORD,
    Element: AdminForgotPassword,
    isProtected: false,
    isPublic: false,
    index: true,
    seo: null,
  },
  {
    label: "Reset Password",
    path: `${routerUrls.ADMIN_RESET_PASSWORD}/:recoveryToken`,
    Element: AdminResetPassword,
    isProtected: false,
    isPublic: false,
    index: true,
    seo: null,
  },

  /* admin dashboard */
  {
    label: "Admin Dashboard",
    path: routerUrls.ADMIN_DASHBOARD,
    Element: AdminDashboard,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: true,
    [adminRoles.SUPERADMIN]: true,
  },

  /* admin blog routes */
  {
    label: "Blogs",
    path: routerUrls.ADMIN_BLOGS,
    Element: BlogList,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: true,
    [adminRoles.SUPERADMIN]: true,
  },
  {
    label: "New Blogs",
    path: routerUrls.ADD_NEW_BLOG,
    Element: AddNewBlog,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: true,
    [adminRoles.SUPERADMIN]: true,
  },
  {
    label: "New Blogs",
    path: routerUrls.UPDATE_BLOG + "/:uid",
    Element: UpdateBlog,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: true,
    [adminRoles.SUPERADMIN]: true,
  },

  /* admin service routes */
  {
    label: "Services",
    path: routerUrls.ADMIN_SERVICES,
    Element: ServiceList,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: true,
    [adminRoles.SUPERADMIN]: true,
  },

  /* admin testimonial routes */
  {
    label: "Services",
    path: routerUrls.ADMIN_TESTIMONIALS,
    Element: Testimonial,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: true,
    [adminRoles.SUPERADMIN]: true,
  },

  /* admin user routes */
  {
    label: "Users",
    path: routerUrls.ADMIN_USERS,
    Element: User,
    isProtected: true,
    isPublic: false,
    index: true,
    seo: null,
    [adminRoles.ADMIN]: false,
    [adminRoles.SUPERADMIN]: true,
  },
];
