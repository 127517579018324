import * as yup from "yup";
import { imageValidation } from "../utils/yupUtils";

export const AddBlogSchema = yup.object().shape({
  title: yup
    .string()
    .max(254, "title can not exceed 254 characters")
    .required("title is a required field"),
  excerpt: yup.string().required("provide a short description for SEO"),
  topic: yup
    .string()
    .max(120, "topic can not exceed 120 characters")
    .required("provide a topic to categorize this blog")
    .nullable(),
  keywords: yup.array().required("provide at least one keyword for SEO"),
  featuredBlogImage: imageValidation.required,
  author: yup.string().max(120, "author's name can not exceed 120 characters").nullable(),
  aboutAuthor: yup
    .string()
    .max(500, "author's introductiom can not exceed 500 characters")
    .nullable(),
  authorProfile: yup.string().nullable(),
  authorImage: imageValidation.optional,
});

export const UpdateBlogSchema = yup.object().shape({
  title: yup
    .string()
    .max(254, "title can not exceed 254 characters")
    .required("title is a required field"),
  excerpt: yup.string().required("provide a short description for SEO"),
  topic: yup
    .string()
    .max(120, "topic can not exceed 120 characters")
    .required("provide a topic to categorize this blog")
    .nullable(),
  keywords: yup.array().required("provide at least one keyword for SEO"),
  featuredBlogImage: imageValidation.optional,
  author: yup.string().max(120, "author's name can not exceed 120 characters").nullable(),
  aboutAuthor: yup
    .string()
    .max(500, "author's introductiom can not exceed 500 characters")
    .nullable(),
  authorProfile: yup.string().nullable(),
  authorImage: imageValidation.optional,
});
