import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";

import { subToNewsletter } from "../../api/contact";
import { feedbackActions } from "../../store/reducers/feedbackReducer";
import Section from "../../widgets/Section";
import { MarkEmailRead } from "@mui/icons-material";

const Newsletter = () => {
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email: event.target.userEmail.value };
    const res = await subToNewsletter(data);
    if (res.data) {
      dispatch(feedbackActions.NOTIFY({ status: "success", message: res.data }));
      document.getElementById("newsletter-form")?.reset();
    }
  };

  return (
    <Section id="subscribe">
      <Container
        maxWidth="lg"
        sx={{
          my: 6,
          padding: 2,
          borderRadius: 1,
          boxSizing: "border-box",
          background: "linear-gradient(187deg, rgba(124,70,221,1) 0%, rgba(93,41,226,1) 100%)",
        }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack
                alignItems="center"
                sx={{ display: { xl: "none", lg: "none", md: "none", sm: "flex", xs: "flex" } }}>
                <Box component="img" src="/assets/images/newsletter.png" height="250px" />
              </Stack>
              <Typography
                mt={2}
                fontWeight="700"
                lineHeight="0.5"
                letterSpacing={2}
                fontSize={{ md: "42px", sm: "36px", xs: "30px" }}
                textAlign={{ xl: "left", lg: "left", md: "left", sm: "center", xs: "center" }}>
                Subscribe Now
              </Typography>
              <Typography
                textAlign={{ xl: "left", lg: "left", md: "left", sm: "center", xs: "center" }}>
                Subscribe to our newsletter of curated reads
              </Typography>
              <Stack
                spacing={2}
                component="form"
                direction="column"
                id="newsletter-form"
                onSubmit={handleSubmit}>
                <TextField
                  required
                  type="email"
                  color="warning"
                  name="userEmail"
                  sx={{ flex: 1 }}
                  label="Subscribe Now"
                  placeholder="Can we have your email?"
                />
                <Button
                  size="large"
                  type="submit"
                  color="warning"
                  variant="contained"
                  sx={{ alignSelf: { md: "flex-start", xs: "center" } }}
                  startIcon={<MarkEmailRead />}>
                  Subscribe
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            sx={{ display: { xl: "flex", lg: "flex", md: "flex", sm: "none", xs: "none" } }}>
            <Box component="img" src="/assets/images/newsletter.png" height="250px" />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default Newsletter;
