import { Get, Patch, Post } from "./request";

export const getAllAdminUsers = async (uid) => {
  const response = await Get(`admin/auth/${uid}`);
  if (response?.status === 200) {
    return response.admins;
  } else {
    return [];
  }
};

export const createNewAdminUser = async (data) => {
  const response = await Post("admin/auth/add-user", data);
  if (response?.status === 200) {
    return { data: response.newAdmin, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};

export const updateExistingAdminUser = async (data, uid) => {
  const response = await Patch(`admin/auth/edit-user/${uid}`, data);
  if (response?.status === 200) {
    return { data: response.updatedAdmin, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};

export const deleteExistingAdminUser = async (formData) => {
  const response = await Post("/admin/auth/delete", formData);
  if (response?.status === 200) {
    return response.successList;
  } else {
    return null;
  }
};
