import { Box } from "@mui/material";
import React from "react";
import ContactUs from "../../components/public/ContactUs";
import BlogSection from "../../components/public/BlogSection";
import ServiceSection from "../../components/public/ServiceSection";
import Testimonials from "../../components/public/Testimonials";
import Banner2 from "../../components/public/Banner2";
import FeaturedBlogs from "../../components/public/FeaturedBlogs";
import Newsletter from "../../components/public/Newsletter";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Home = ({ seo }) => {
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    } else {
      location.href = location.hash;
    }
  }, []);

  return (
    <Box width="100%">
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <Banner2 />
      <FeaturedBlogs />
      <ServiceSection />
      <BlogSection />
      <Testimonials />
      <ContactUs />
      <Newsletter />
    </Box>
  );
};

export default Home;
