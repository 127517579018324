import React, { useState } from "react";
import {
  Box,
  List,
  Stack,
  Drawer,
  ListItem,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";

import { pages } from "../constants/links";
import { ROOT } from "../constants/routerUrls";

const Navabr = () => {
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 3,
        display: "flex",
        bgcolor: "#000000",
        alignItems: "center",
        justifyContent: "center",
        height: { xl: 85, lg: 75, md: 75, sm: 75, xs: 75 },
      }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {location.pathname !== ROOT ? (
            <Link to="/">
              <Box
                alt="R-Squared"
                component="img"
                src="/assets/images/rsq-logo.png"
                sx={{
                  mr: 2,
                  width: "40px",
                  height: "40px",
                  borderRadius: 1,
                  display: { xs: "none", md: "flex" },
                }}
              />
            </Link>
          ) : (
            <Box component="a" href="#">
              <Box
                alt="R-Squared"
                component="img"
                src="/assets/images/rsq-logo.png"
                sx={{
                  mr: 2,
                  width: "40px",
                  height: "40px",
                  borderRadius: 1,
                  display: { xs: "none", md: "flex" },
                }}
              />
            </Box>
          )}

          {/* MOBILE LOGO */}
          <Stack
            href="/"
            width="100%"
            component="a"
            direction="row"
            justifyContent="flex-start"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Box
              alt="R-Squared"
              component="img"
              src="/assets/images/rsq-logo.png"
              sx={{
                mr: 2,
                width: "40px",
                height: "40px",
                borderRadius: 1,
              }}
            />
          </Stack>

          {/* MOBILE DRAWER */}
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              color="inherit"
              aria-haspopup="true"
              aria-controls="menu-appbar"
              aria-label="account of current user"
              onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon color="white" />
            </IconButton>
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <List sx={{ width: "240px" }}>
                {pages.map((page) => (
                  <ListItem
                    component="a"
                    key={page.label}
                    href={page.anchor}
                    onClick={() => setOpenDrawer(!openDrawer)}>
                    <Typography
                      fontWeight="bold"
                      textAlign="center"
                      sx={{
                        color:
                          location?.hash === page.anchor.replace("/", "") ? "#F57C00" : "white",
                        borderLeft:
                          location?.hash === page.anchor.replace("/", "")
                            ? "5px solid #F57C00"
                            : "none",
                        paddingLeft:
                          location?.hash === page.anchor.replace("/", "") ? "5px" : "0px",
                      }}>
                      {page.label}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Box>

          {/* ANCHORS */}
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) =>
              location.pathname !== ROOT ? (
                <Link
                  key={page.label}
                  to={page.anchor}
                  className="nav-links"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: 0.5,
                    width: "fit-content",
                    textDecoration: "none",
                    textTransform: "capitalize",
                    color: location?.hash === page.anchor.replace("/", "") ? "#F57C00" : "white",
                    paddingBottom: location?.hash === page.anchor.replace("/", "") ? "5px" : "0px",
                    borderBottom:
                      location?.hash === page.anchor.replace("/", "")
                        ? "5px solid #F57C00"
                        : "none",
                    "&:hover": {
                      color: "#F57C00",
                    },
                    "&:active": {
                      color: "#F57C00",
                    },
                  }}>
                  {page.label}
                </Link>
              ) : (
                <Typography
                  size="small"
                  component="a"
                  key={page.label}
                  href={page.anchor}
                  className="nav-links"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: 0.5,
                    width: "fit-content",
                    textDecoration: "none",
                    textTransform: "capitalize",
                    color: location?.hash === page.anchor.replace("/", "") ? "#F57C00" : "white",
                    paddingBottom: location?.hash === page.anchor.replace("/", "") ? "5px" : "0px",
                    borderBottom:
                      location?.hash === page.anchor.replace("/", "")
                        ? "5px solid #F57C00"
                        : "none",
                    "&:hover": {
                      color: "#F57C00",
                    },
                    "&:active": {
                      color: "#F57C00",
                    },
                  }}>
                  {page.label}
                </Typography>
              ),
            )}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navabr;
