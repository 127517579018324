import { Patch, Post } from "./request";

export const loginUser = async (data) => {
  const response = await Post("/admin/auth/sign-in", data);
  if (response?.status === 200) {
    return {
      data: {
        user: response?.user,
        token: response?.token,
      },
      error: null,
    };
  } else {
    return { data: null, error: response.msg };
  }
};

export const updateAdminUserProfile = async (data, uid) => {
  const response = await Patch(`admin/auth/update-profile/${uid}`, data);
  if (response?.status === 200) {
    return { data: response.updatedProfile, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};

export const logout = async () => {
  const response = await Post("/admin/auth/logout");
  if (response?.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const recoverAccount = async (data) => {
  const response = await Post("/admin/auth/recover", data);
  if (response?.status === 200) {
    return { data: response?.msg, error: null };
  } else {
    return { data: null, error: response?.msg };
  }
};

export const resetPassword = async (data, token) => {
  const response = await Patch(`/admin/auth/reset-password/${token}`, data);
  if (response?.status === 200) {
    return { data: response?.msg, error: null };
  } else {
    return { data: null, error: response?.msg };
  }
};

export const changeAdminPassword = async (data) => {
  const response = await Patch("/admin/auth/change-password", data);
  if (response?.status === 200) {
    return { data: response?.msg, error: null };
  } else {
    return { data: null, error: response?.msg };
  }
};
