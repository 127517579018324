import React from "react";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../global/Tooltip";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { adminSidebarList1, adminSidebarList2 } from "../../constants/adminSidebarItems";
import { useSelector } from "react-redux";

const AdminSidebar = ({ isOpen }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  return (
    <Stack justifyContent="space-between" height="100%">
      <List>
        {adminSidebarList1.map((item) => (
          <CustomTooltip key={item.path} title={isOpen ? "" : item.label} placement="right">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => navigate(item.path, { state: { from: location.pathname } })}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          </CustomTooltip>
        ))}
      </List>
      <Box>
        <Divider />
        <List>
          {adminSidebarList2.map(
            (item) =>
              item[user?.role] && (
                <CustomTooltip key={item.path} title={isOpen ? "" : item.label} placement="right">
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => navigate(item.path, { state: { from: location.pathname } })}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        sx={{ opacity: open ? 1 : 0 }}
                        style={{ color: "white" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </CustomTooltip>
              ),
          )}
        </List>
      </Box>
    </Stack>
  );
};

export default AdminSidebar;
