import React from "react";
import { Stack, Box } from "@mui/material";

const AuthScreen = (props) => {
  return (
    <Stack
      p={2}
      spacing={5}
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center">
      <Box component="img" src="/assets/images/rsq-logo.png" width="5%" minWidth="70px" />
      {props.children}
    </Stack>
  );
};

export default AuthScreen;
