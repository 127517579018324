import * as yup from "yup";

export const createUserValidationSchema = yup.object().shape({
  name: yup.string().required("name is a required field"),
  email: yup.string().email().required("email is a required field"),
  role: yup.string().required("role is a required field"),
});

export const updateUserValidationSchema = yup.object().shape({
  name: yup.string().required("name is a required field"),
  email: yup.string().email().required("email is a required field"),
  role: yup.string().required("role is a required field"),
  status: yup.string().required("status is a required field"),
});

export const deleteUserValidationSchema = yup.object().shape({
  password: yup.string().required("password is a required field"),
});
