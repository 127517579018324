import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Chip,
  Input,
  Stack,
  Button,
  TextField,
  Typography,
  InputLabel,
  CardContent,
  FormControl,
  Autocomplete,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import List from "@editorjs/list";
import Images from "@editorjs/image";
import Headers from "@editorjs/header";
import Warnings from "@editorjs/warning";

import { useForm, useWatch } from "react-hook-form";
import { createReactEditorJS } from "react-editor-js";
import { yupResolver } from "@hookform/resolvers/yup";

import { PhotoCamera } from "@mui/icons-material";
import Page from "../../../components/global/Page";

import { uploadFile } from "../../../api/file";
import debounce from "../../../utils/debounce";
import { useNavigate } from "react-router-dom";
import { AddBlogSchema } from "../../../validation/blog";
import { ADMIN_BLOGS } from "../../../constants/routerUrls";
import { createNewBlog, fetchkeywordSuggestions, fetchTopicSuggestions } from "../../../api/blog";

const Editor = createReactEditorJS();

const AddNewBlog = () => {
  const bodyImageCache = [];
  const navigate = useNavigate();
  const [body, setBody] = useState({});
  const [topics, setStopics] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  const editorCore = React.useRef(null);
  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const resolver = yupResolver(AddBlogSchema);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const keywords = useWatch({ control, name: "keywords" });
  const blogAuthorImage = useWatch({ control, name: "blogAuthorImage" });
  const featuredBlogImage = useWatch({ control, name: "featuredBlogImage" });

  const fetchTopics = async (val) => {
    const response = await fetchTopicSuggestions(val);
    setStopics(response);
  };

  const fetchKeywords = async (val) => {
    const response = await fetchkeywordSuggestions(val);
    setKeywordList(response);
  };

  useEffect(() => {
    fetchTopics();
    fetchKeywords();
  }, []);

  const handleEditorChange = async () => {
    const data = await editorCore.current.save();
    setBody(data);
  };

  const savePost = async (data) => {
    const result = await createNewBlog({
      ...data,
      body,
      status: "DRAFT",
      keywords: data.keywords?.toString() || "",
      blogAuthorImage: data.blogAuthorImage?.[0],
      featuredBlogImage: data.featuredBlogImage?.[0],
    });
    if (result) navigate(ADMIN_BLOGS);
  };

  const publishPost = async (data) => {
    const result = await createNewBlog({
      ...data,
      body,
      status: "PUBLISHED",
      keywords: data.keywords?.toString() || "",
      blogAuthorImage: data.blogAuthorImage?.[0],
      featuredBlogImage: data.featuredBlogImage?.[0],
    });
    if (result) navigate(ADMIN_BLOGS);
  };

  const handleTopicSelection = (event, value) => {
    setValue("topic", value);
  };

  const handleKeywordSelection = (event, value) => {
    setValue("keywords", value);
  };

  const onTopicChange = (val) => {
    fetchTopics(val);
  };

  const onKeywordChange = (val) => {
    fetchKeywords(val);
  };

  return (
    <Page>
      <Card>
        <CardContent sx={{ p: 1 }}>
          <Typography p={1} fontSize="1.2rem" fontWeight={800}>
            Blog Meta Information
          </Typography>
          <FormControl
            error={errors?.featuredBlogImage}
            sx={{ flex: 1, minWidth: "180px", margin: "8px" }}>
            <label htmlFor="featured-image-file">
              <Input
                type="file"
                id="featured-image-file"
                sx={{ display: "none" }}
                {...register("featuredBlogImage")}
              />
              {featuredBlogImage?.[0] ? (
                <span>
                  <img
                    width="100px"
                    style={{ cursor: "pointer" }}
                    src={URL.createObjectURL(featuredBlogImage[0])}
                  />
                </span>
              ) : (
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<PhotoCamera />}
                  color={errors?.featuredBlogImage ? "error" : "primary"}>
                  Featured Image
                </Button>
              )}
            </label>
            {errors?.featuredBlogImage && (
              <FormHelperText>{errors?.featuredBlogImage?.message}</FormHelperText>
            )}
          </FormControl>

          <Stack direction="row" flexWrap="wrap" alignItems="flex-start" justifyContent="center">
            <FormControl sx={{ flex: 1, minWidth: "180px", margin: "8px" }} error={errors.title}>
              <InputLabel>Title</InputLabel>
              <OutlinedInput
                label="Title"
                {...register("title")}
                placeholder="Title for your post"
              />
              {errors?.title && <FormHelperText>{errors?.title?.message}</FormHelperText>}
            </FormControl>

            <FormControl sx={{ flex: 1, minWidth: "180px", margin: "8px" }} error={errors?.excerpt}>
              <InputLabel>Excerpt</InputLabel>
              <OutlinedInput
                label="Excerpt"
                {...register("excerpt")}
                placeholder="Short description for better SEO"
              />
              {errors?.excerpt && <FormHelperText>{errors?.excerpt?.message}</FormHelperText>}
            </FormControl>

            <FormControl sx={{ flex: 1, minWidth: "180px", margin: "8px" }} error={errors?.topic}>
              <Autocomplete
                freeSolo
                options={topics}
                onChange={handleTopicSelection}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Topic"
                    variant="outlined"
                    error={errors?.topic}
                    {...register("topic")}
                    placeholder="Select topic to categorize the blog"
                    onChange={(e) => onTopicChange(e?.target?.value)}
                  />
                )}
              />
              {errors?.topic && <FormHelperText>{errors?.topic?.message}</FormHelperText>}
            </FormControl>

            <FormControl
              error={errors?.keywords}
              sx={{ flex: 1, minWidth: "180px", margin: "8px" }}>
              <Autocomplete
                multiple
                options={keywordList}
                onChange={handleKeywordSelection}
                freeSolo={keywords?.length >= 3 ? false : true}
                getOptionDisabled={() => (keywords?.length >= 3 ? true : false)}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      variant="outlined"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Keywords"
                    variant="outlined"
                    error={errors?.keywords}
                    placeholder="Add upto three keywords"
                    onChange={(e) => onKeywordChange(e?.target?.value)}
                  />
                )}
              />
              {errors?.keywords && <FormHelperText>{errors?.keywords?.message}</FormHelperText>}
            </FormControl>
          </Stack>

          <Typography p={1} fontSize="1.2rem" fontWeight={800}>
            Author&apos;s Information
          </Typography>
          <FormControl
            error={errors?.blogAuthorImage}
            sx={{ flex: 1, minWidth: "180px", margin: "8px" }}>
            <label htmlFor="author-image-file">
              <Input
                type="file"
                id="author-image-file"
                sx={{ display: "none" }}
                {...register("blogAuthorImage")}
              />
              {blogAuthorImage?.[0] ? (
                <span>
                  <img
                    width="100px"
                    style={{ cursor: "pointer" }}
                    src={URL.createObjectURL(blogAuthorImage?.[0])}
                  />
                </span>
              ) : (
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<PhotoCamera />}
                  color={errors?.blogAuthorImage ? "error" : "primary"}>
                  Author&apos;s Image
                </Button>
              )}
            </label>
            {errors?.blogAuthorImage && (
              <FormHelperText>{errors?.blogAuthorImage?.message}</FormHelperText>
            )}
          </FormControl>

          <Stack direction="row" flexWrap="wrap" alignItems="flex-start" justifyContent="center">
            <FormControl sx={{ flex: 1, minWidth: "180px", margin: "8px" }} error={errors.author}>
              <InputLabel>Author&apos;s Name</InputLabel>
              <OutlinedInput
                label="Author's Name"
                {...register("author")}
                placeholder="Author of this blog"
              />
              {errors?.author && <FormHelperText>{errors?.author?.message}</FormHelperText>}
            </FormControl>

            <FormControl
              sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
              error={errors?.aboutAuthor}>
              <InputLabel>About Author</InputLabel>
              <OutlinedInput
                label="About Author"
                {...register("aboutAuthor")}
                placeholder="Short introduction of the author"
              />
              {errors?.aboutAuthor && (
                <FormHelperText>{errors?.aboutAuthor?.message}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
              error={errors?.authorProfile}>
              <InputLabel>Profile Link</InputLabel>
              <OutlinedInput
                label="Profile Link"
                {...register("authorProfile")}
                placeholder="Link to any social media or portfolio"
              />
              {errors?.authorProfile && (
                <FormHelperText>{errors?.authorProfile?.message}</FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: 3, px: 1, width: "100%" }}>
            <Button variant="contained" size="small" onClick={handleSubmit(publishPost)}>
              Publish
            </Button>
            <Button variant="contained" size="small" onClick={handleSubmit(savePost)}>
              Save As Draft
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {/* EDITOR */}
      <Typography fontSize="1.2rem" textAlign={{ md: "center" }} fontWeight={800} sx={{ my: 3 }}>
        Content
      </Typography>

      <Editor
        holder="content-holder"
        onChange={() => debounce(handleEditorChange)()}
        onInitialize={handleInitialize}
        tools={{
          header: Headers,
          image: {
            class: Images,
            config: {
              field: "image",
              uploader: {
                async uploadByFile(file) {
                  const data = await uploadFile(file, "image");
                  bodyImageCache.push(data);
                  return {
                    success: 1,
                    file: data,
                  };
                },
              },
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: "unordered",
            },
          },
          warning: Warnings,
        }}
        placeholder="Start typing here. . . . .">
        <Box id="content-holder" />
      </Editor>
    </Page>
  );
};

export default AddNewBlog;
