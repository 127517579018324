import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Button,
  colors,
  styled,
  Container,
  Typography,
  Autocomplete,
  CircularProgress,
  TextField as MuiTextField,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowCircleRight, Search } from "@mui/icons-material";

import Section from "../../widgets/Section";
import { BLOG } from "../../constants/routerUrls";
import { fetchBlogs, fetchSuggestion } from "../../api/blog";

const TextField = styled(MuiTextField)({
  "& .MuiInputBase-root > input": {
    fontSize: "1rem",
    fontWeight: 400,
  },
});

const AllBlogs = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [allBlogs, setAllBlogs] = useState([...Array(6)]);
  const [isCardHovered, setIsCardHovered] = useState(false);

  const getData = async (query) => {
    setIsloading(true);
    const response = await fetchSuggestion(query);
    setSuggestions(response);
    setIsloading(false);
  };

  const fetchList = async () => {
    setLoader(true);
    const list = await fetchBlogs({ name: selectedBlog?.title, page });
    if (list) {
      setAllBlogs(list.blogs);
      setTotal(list.total);
    }
    setLoader(false);
  };

  useEffect(() => {
    (async () => await fetchList())();
  }, [page, selectedBlog]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTotalPage = () => {
    const totalPage = total / 15;
    if (totalPage < 1) {
      return 1;
    } else {
      return Math.floor(totalPage);
    }
  };

  return (
    <Section>
      <Container size="lg">
        <Stack>
          <Autocomplete
            autoComplete
            options={suggestions}
            filterSelectedOptions
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.slug === value.slug}
            onChange={(event, blog) => setSelectedBlog(blog)}
            onInputChange={async (event, value) => {
              await getData(value);
            }}
            renderInput={(params) => (
              <TextField
                autoFocus
                {...params}
                label="Search"
                placeholder="Search Blog by name"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : <Search />}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack px={2} mt="5vh" direction="row" flexWrap="wrap">
          {allBlogs?.map((item, index) => (
            <Box
              height="450px"
              component={Link}
              position="relative"
              className="blog-links"
              key={item?.uid || index}
              bgcolor={colors.grey[900]}
              to={`${BLOG}/${item?.slug}`}
              onMouseLeave={() => setIsCardHovered("")}
              onMouseEnter={() => setIsCardHovered(item?.uid)}
              width={{ xl: "33%", lg: "33.33%", md: "50%", sm: "100%", xs: "100%" }}>
              <Box width="100%" height="100%" overflow="hidden" position="absolute" top="0">
                <Box
                  width="100%"
                  height="100%"
                  component="img"
                  src={item?.featuredImage}
                  sx={{
                    overflow: "hidden",
                    transition: "0.5s ease",
                    transform: isCardHovered === item?.uid ? "scale(1.2)" : "scale(1.01)",
                  }}
                />
              </Box>

              <Box
                top="0"
                width="100%"
                height="100%"
                position="absolute"
                sx={{
                  background:
                    "linear-gradient(187deg, rgba(30,30,42,0.47692580450148814) 0%, rgba(29,23,42,1) 100%)",
                }}
              />

              <Box
                px={2}
                py={2}
                top="0"
                width="100%"
                height="100%"
                position="absolute"
                boxSizing="border-box">
                <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                  <Typography
                    fontSize={{
                      sm: "1.8rem",
                      xs: "1.6rem",
                    }}
                    fontWeight={600}>
                    {loader ? (
                      <>
                        <Skeleton />
                        <Skeleton width="45%" />
                      </>
                    ) : (
                      `${item?.title?.substring(0, 64)}${item?.title?.length > 64 ? "..." : ""}`
                    )}
                  </Typography>
                  <Stack spacing={3}>
                    <Typography fontSize="1rem" sx={{ maxWidth: "85%" }}>
                      {loader ? (
                        <>
                          <Skeleton />
                          <Skeleton width="75%" />
                          <Skeleton width="85%" />
                        </>
                      ) : (
                        `${item?.excerpt?.substring(0, 256)}${
                          item?.excerpt?.length > 256 ? "..." : ""
                        }`
                      )}
                    </Typography>
                    {loader ? (
                      <Skeleton width="25%" height={50} />
                    ) : (
                      <Button
                        sx={{
                          fontWeight: 700,
                          letterSpacing: 1,
                          width: "max-content",
                          textTransform: "capitalize",
                        }}
                        endIcon={<ArrowCircleRight />}>
                        Learn More
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Box>
          ))}
        </Stack>

        <Stack
          spacing={2}
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 5 }}>
          <Button disabled={page <= 1} onClick={() => setPage((page) => page - 1)}>
            Previous
          </Button>
          <Typography fontWeight={600}>
            Showing page {page} of {getTotalPage()}
          </Typography>
          <Button disabled={page >= getTotalPage()} onClick={() => setPage((page) => page + 1)}>
            Next
          </Button>
        </Stack>
      </Container>
    </Section>
  );
};

export default AllBlogs;
