import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Skeleton, Stack, Typography } from "@mui/material";

import Section from "../../widgets/Section";

import { fetchAllTestimonialsPublicSide } from "../../api/testimonials";

const MotionBox = motion(Box);

const Testimonials = () => {
  const [loading, setLoading] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const matches900 = useMediaQuery("(max-width:900px)");

  const fetchTestimonials = async () => {
    setLoading(true);
    const data = await fetchAllTestimonialsPublicSide();
    setTestimonials(data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => await fetchTestimonials())();
  }, []);

  return (
    <Section mt="10vh">
      <Box>
        <Typography
          pb="5vh"
          fontWeight="700"
          lineHeight="1.2"
          letterSpacing={2}
          textAlign="center"
          fontSize={{ md: "42px", sm: "36px", xs: "30px" }}>
          Clients <br /> we&apos;ve worked with
        </Typography>
      </Box>
      <MotionBox maxWidth="md" disableGutters sx={{ margin: "auto" }}>
        {loading ? (
          <Skeleton height={400} />
        ) : (
          <Carousel
            stopOnHover
            interval={3500}
            autoPlay={true}
            swipeable={true}
            centerMode={true}
            showStatus={false}
            infiniteLoop={true}
            emulateTouch={true}
            centerSlidePercentage={matches900 ? 100 : 33.33}>
            {testimonials.map((client, index) => (
              <Stack
                px={2}
                py={5}
                key={index}
                height="100%"
                target="_blank"
                href={client?.url}
                alignItems="center"
                justifyContent="center"
                component={client?.url ? "a" : null}
                sx={{
                  filter: "grayscale(100%)",
                  ":hover": { filter: "grayscale(0%)" },
                }}>
                <Box
                  sx={{
                    padding: 3,
                    borderRadius: "10px",
                  }}>
                  <Box
                    height={180}
                    component="img"
                    borderRadius={2}
                    src={client?.image || "/assets/images/logo-placeholder.png"}
                  />

                  <Typography
                    mt={1}
                    color="white"
                    fontWeight={700}
                    fontSize="1.5rem"
                    textAlign="center">
                    {client.name}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Carousel>
        )}
      </MotionBox>
    </Section>
  );
};

export default Testimonials;
