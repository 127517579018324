import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ADMIN_DASHBOARD, ADMIN_LOGIN } from "../../constants/routerUrls";

const AdminRoot = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  /**
   * if admin authenticated redirect to
   * dashboard
   * else
   * redirect to admin login
   */
  if (auth?.isAuth) {
    window.setTimeout(function () {
      navigate(ADMIN_DASHBOARD, { replace: true });
    }, 1500);
  } else {
    window.setTimeout(function () {
      navigate(ADMIN_LOGIN, { replace: true });
    }, 1500);
  }

  return (
    <Stack>
      <Typography textAlign="center" mt={25}>
        Please wait, you are being redirected to R-SQUARED admin portal . . .
        <br />
        If you are not redirected automatically, please <Link to={ADMIN_LOGIN}>click here</Link>
      </Typography>
    </Stack>
  );
};

export default AdminRoot;
