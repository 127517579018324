import React from "react";
import Page from "../global/Page";
import { Typography, Stack } from "@mui/material";

const Unauthorized = () => {
  return (
    <Page>
      <Stack width="100%" pt="10vh">
        <Typography textAlign="center" color="error">
          You are not authorized to access this section!
        </Typography>
      </Stack>
    </Page>
  );
};

export default Unauthorized;
