import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Section from "../../widgets/Section";
import { fetchAllServicesPublicSide } from "../../api/services";
import { Box, Container, Grid, Skeleton, Stack, Typography } from "@mui/material";

const MotionContainer = motion(Container);

const ServiceSection = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchServices = async () => {
    setLoading(true);
    const data = await fetchAllServicesPublicSide();
    setServices(data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => await fetchServices())();
  }, []);

  return (
    <Section id="service">
      <Box>
        <Typography
          pb="5vh"
          fontWeight="700"
          lineHeight="1.2"
          letterSpacing={2}
          textAlign="center"
          fontSize={{ md: "42px", sm: "36px", xs: "30px" }}>
          We believe in <br /> sharing our expertise
        </Typography>
      </Box>
      <MotionContainer
        maxWidth="lg"
        disableGutters
        sx={{
          padding: 1,
          borderRadius: 2,
          position: "relative",
        }}>
        <Grid width="100%" container boxSizing="border-box" px={1} py={1} gap={2}>
          {loading
            ? [...Array(3)].map((item) => (
                <Grid
                  item
                  p={2}
                  xs={12}
                  sm={12}
                  md={5.89}
                  lg={3.89}
                  key={item}
                  borderRadius={1}
                  boxSizing="border-box">
                  <Box width="100%" height="400px">
                    <Skeleton variant="circular" width={55} height={55} />
                    <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
                    <Skeleton variant="text" width="80%" sx={{ mt: 2 }} />
                    <Skeleton variant="text" width="90%" />
                    <Skeleton variant="text" width="75%" />
                  </Box>
                </Grid>
              ))
            : services.map((item) => (
                <Grid
                  item
                  p={2}
                  xs={12}
                  sm={12}
                  md={5.89}
                  lg={3.89}
                  // component="a"
                  // href="#contact"
                  borderRadius={1}
                  key={item.title}
                  boxSizing="border-box"
                  // className="service-links"
                  sx={{
                    transition: "0.4s ease",
                    "&:hover": {
                      background:
                        "linear-gradient(187deg, rgba(124,70,221,1) 0%, rgba(93,41,226,1) 100%)",
                    },
                  }}>
                  <Stack spacing={2}>
                    <Box
                      component="img"
                      src={item.image}
                      sx={{ width: "55px", height: "55px" }}
                      bgcolor="white"
                      borderRadius={2}
                      px={1}
                      py={1}
                    />
                    <Typography variant="h5" fontSize={{ md: "26px", sm: "20px" }} fontWeight="700">
                      {item.title}
                    </Typography>
                    <Typography variant="body" fontSize="0.9rem" fontWeight="500">
                      {item.description.substring(0, 320)}
                      {item.description.length > 320 ? "..." : ""}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
        </Grid>
      </MotionContainer>
    </Section>
  );
};

export default ServiceSection;
