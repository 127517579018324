import React from "react";
import { Alert, colors, Stack, Typography } from "@mui/material";

const AuthForm = ({
  heading,
  message,
  subHeading,
  onSubmitCallback,
  messageType = "info",
  children,
  ...rest
}) => {
  return (
    <Stack
      component="form"
      minWidth="320px"
      maxWidth="580px"
      borderRadius={1}
      bgcolor={colors.grey[900]}
      p={{ lg: 4, md: 4, sm: 3, xs: 2 }}
      width={{ lg: "40%", md: "50%", sm: "65%", xs: "80%" }}
      onSubmit={onSubmitCallback}
      {...rest}>
      <Typography
        fontSize={{ lg: "1.8rem", md: "1.7rem", sm: "1.6rem", xs: "1.5rem" }}
        fontWeight="500">
        {heading}
      </Typography>
      <Typography fontSize={{ lg: "1.2rem", xs: "1.1rem" }} fontWeight="400" mb={4}>
        {subHeading}
      </Typography>
      {message && (
        <Alert severity={messageType} sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}
      {children}
    </Stack>
  );
};

export default AuthForm;
