import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Stack,
  Input,
  Button,
  TextField,
  InputLabel,
  Typography,
  CardContent,
  FormControl,
  Autocomplete,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import List from "@editorjs/list";
import Images from "@editorjs/image";
import Headers from "@editorjs/header";
import Warnings from "@editorjs/warning";

import { useForm, useWatch } from "react-hook-form";
import { createReactEditorJS } from "react-editor-js";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

import { PhotoCamera } from "@mui/icons-material";
import Page from "../../../components/global/Page";

import {
  fetchBlog,
  updateBlog,
  fetchTopicSuggestions,
  updateBlogAuthorImage,
  fetchkeywordSuggestions,
  updateFeaturedBlogImage,
} from "../../../api/blog";
import { uploadFile } from "../../../api/file";
import { ADMIN_BLOGS } from "../../../constants/routerUrls";
import { UpdateBlogSchema } from "../../../validation/blog";

const Editor = createReactEditorJS();

const UpdateBlog = () => {
  const { uid } = useParams();
  const [topics, setStopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [keywordList, setKeywordList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const blog = await fetchBlog(uid);
    setBlogData(blog);
    setLoading(false);
  };

  useEffect(() => {
    if (uid) (async () => await fetchData())();
  }, [uid]);

  const editorCore = React.useRef(null);
  const navigate = useNavigate();
  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const resolver = yupResolver(UpdateBlogSchema);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: "onChange" });

  const keywords = useWatch({ control, name: "keywords" });
  const blogAuthorImage = useWatch({ control, name: "blogAuthorImage" });
  const featuredBlogImage = useWatch({ control, name: "featuredBlogImage" });

  const fetchTopics = async (val) => {
    const response = await fetchTopicSuggestions(val);
    setStopics(response);
  };

  const fetchKeywords = async (val) => {
    const response = await fetchkeywordSuggestions(val);
    setKeywordList(response);
  };

  useEffect(() => {
    fetchTopics();
    fetchKeywords();
  }, []);

  useEffect(() => {
    if (blogData) {
      setValue("title", blogData?.title);
      setValue("topic", blogData?.topic);
      setValue("author", blogData?.author);
      setValue("excerpt", blogData?.excerpt);
      setValue("aboutAuthor", blogData?.aboutAuthor);
      setValue("authorProfile", blogData?.authorProfile);
      setValue("keywords", blogData?.keywords?.split(","));
    }
  }, [blogData]);

  const savePost = async (data) => {
    const savedData = await editorCore.current.save();
    if (featuredBlogImage?.[0]) {
      await updateFeaturedBlogImage({
        uid: blogData?.uid,
        featuredBlogImage: featuredBlogImage[0],
      });
    }
    if (blogAuthorImage?.[0]) {
      await updateBlogAuthorImage({
        uid: blogData?.uid,
        blogAuthorImage: blogAuthorImage[0],
      });
    }
    const result = await updateBlog({
      ...data,
      status: "DRAFT",
      body: savedData,
      uid: blogData?.uid,
      keywords: data.keywords?.toString() || "",
    });

    if (result) navigate(ADMIN_BLOGS);
  };

  const publishPost = async (data) => {
    const savedData = await editorCore.current.save();
    if (featuredBlogImage?.[0]) {
      await updateFeaturedBlogImage({
        uid: blogData?.uid,
        featuredBlogImage: featuredBlogImage[0],
      });
    }
    if (blogAuthorImage?.[0]) {
      await updateBlogAuthorImage({
        uid: blogData?.uid,
        blogAuthorImage: blogAuthorImage[0],
      });
    }
    const result = await updateBlog({
      ...data,
      body: savedData,
      status: "PUBLISHED",
      uid: blogData?.uid,
      keywords: data.keywords?.toString() || "",
    });

    if (result) navigate(ADMIN_BLOGS);
  };

  const handleTopicSelection = (event, value) => {
    setValue("topic", value);
  };

  const handleKeywordSelection = (event, value) => {
    setValue("keywords", value);
  };

  const onTopicChange = (val) => {
    fetchTopics(val);
  };

  const onKeywordChange = (val) => {
    fetchKeywords(val);
  };

  return (
    <Page>
      {loading || !blogData?.id ? (
        <Stack width="100%" pt={10} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Card>
            <CardContent sx={{ p: 1 }}>
              <Typography p={1} fontSize="1.2rem" fontWeight={800}>
                Blog Meta Information
              </Typography>
              <FormControl
                error={errors?.featuredBlogImage}
                sx={{ flex: 1, minWidth: "180px", margin: "8px" }}>
                <label htmlFor="featured-image-file">
                  <Input
                    type="file"
                    id="featured-image-file"
                    sx={{ display: "none" }}
                    {...register("featuredBlogImage")}
                  />
                  {!featuredBlogImage?.[0] && blogData?.featuredImage ? (
                    <span>
                      <img
                        width="100px"
                        src={blogData.featuredImage}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  ) : featuredBlogImage?.[0] ? (
                    <span>
                      <img
                        width="100px"
                        style={{ cursor: "pointer" }}
                        src={URL.createObjectURL(featuredBlogImage[0])}
                      />
                    </span>
                  ) : (
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<PhotoCamera />}
                      color={errors?.featuredBlogImage ? "error" : "primary"}>
                      Featured Image
                    </Button>
                  )}
                </label>
                {errors?.featuredBlogImage && (
                  <FormHelperText>{errors?.featuredBlogImage?.message}</FormHelperText>
                )}
              </FormControl>

              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="flex-start"
                justifyContent="center">
                <FormControl
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
                  error={errors.title}>
                  <InputLabel>Title</InputLabel>
                  <OutlinedInput
                    label="Title"
                    {...register("title")}
                    placeholder="Title for your post"
                  />
                  {errors?.title && <FormHelperText>{errors?.title?.message}</FormHelperText>}
                </FormControl>

                <FormControl
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
                  error={errors?.excerpt}>
                  <InputLabel>Excerpt</InputLabel>
                  <OutlinedInput
                    label="Excerpt"
                    {...register("excerpt")}
                    placeholder="Short description for better SEO"
                  />
                  {errors?.excerpt && <FormHelperText>{errors?.excerpt?.message}</FormHelperText>}
                </FormControl>

                <FormControl
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
                  error={errors?.topic}>
                  <Autocomplete
                    freeSolo
                    options={topics}
                    defaultValue={blogData?.topic}
                    onChange={handleTopicSelection}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Topic"
                        variant="outlined"
                        error={errors?.topic}
                        {...register("topic")}
                        placeholder="Select topic to categorize the blog"
                        onChange={(e) => onTopicChange(e?.target?.value)}
                      />
                    )}
                  />
                  {errors?.topic && <FormHelperText>{errors?.topic?.message}</FormHelperText>}
                </FormControl>

                <FormControl
                  error={errors?.keywords}
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}>
                  <Autocomplete
                    multiple
                    options={keywordList}
                    onChange={handleKeywordSelection}
                    defaultValue={blogData?.keywords?.split(",")}
                    freeSolo={keywords?.length >= 3 ? false : true}
                    getOptionDisabled={() => (keywords?.length >= 3 ? true : false)}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          key={index}
                          label={option}
                          variant="outlined"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Keywords"
                        variant="outlined"
                        error={errors?.keywords}
                        placeholder="Add upto three keywords"
                        onChange={(e) => onKeywordChange(e?.target?.value)}
                      />
                    )}
                  />
                  {errors?.keywords && <FormHelperText>{errors?.keywords?.message}</FormHelperText>}
                </FormControl>
              </Stack>

              <Typography p={1} fontSize="1.2rem" fontWeight={800}>
                Author&apos;s Information
              </Typography>
              <FormControl
                error={errors?.blogAuthorImage}
                sx={{ flex: 1, minWidth: "180px", margin: "8px" }}>
                <label htmlFor="author-image-file">
                  <Input
                    type="file"
                    id="author-image-file"
                    sx={{ display: "none" }}
                    {...register("blogAuthorImage")}
                  />
                  {!blogAuthorImage?.[0] && blogData?.authorImage ? (
                    <span>
                      <img width="100px" src={blogData.authorImage} style={{ cursor: "pointer" }} />
                    </span>
                  ) : blogAuthorImage?.[0] ? (
                    <span>
                      <img
                        width="100px"
                        style={{ cursor: "pointer" }}
                        src={URL.createObjectURL(blogAuthorImage[0])}
                      />
                    </span>
                  ) : (
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<PhotoCamera />}
                      color={errors?.blogAuthorImage ? "error" : "primary"}>
                      Author&apos; Image
                    </Button>
                  )}
                </label>
                {errors?.blogAuthorImage && (
                  <FormHelperText>{errors?.blogAuthorImage?.message}</FormHelperText>
                )}
              </FormControl>

              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="flex-start"
                justifyContent="center">
                <FormControl
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
                  error={errors.author}>
                  <InputLabel>Author&apos;s Name</InputLabel>
                  <OutlinedInput
                    label="Author's Name"
                    {...register("author")}
                    placeholder="Author of this blog"
                  />
                  {errors?.author && <FormHelperText>{errors?.author?.message}</FormHelperText>}
                </FormControl>

                <FormControl
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
                  error={errors?.aboutAuthor}>
                  <InputLabel>About Author</InputLabel>
                  <OutlinedInput
                    label="About Author"
                    {...register("aboutAuthor")}
                    placeholder="Short introduction of the author"
                  />
                  {errors?.aboutAuthor && (
                    <FormHelperText>{errors?.aboutAuthor?.message}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  sx={{ flex: 1, minWidth: "180px", margin: "8px" }}
                  error={errors?.authorProfile}>
                  <InputLabel>Profile Link</InputLabel>
                  <OutlinedInput
                    label="Profile Link"
                    {...register("authorProfile")}
                    placeholder="Link to any social media or portfolio"
                  />
                  {errors?.authorProfile && (
                    <FormHelperText>{errors?.authorProfile?.message}</FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack
                spacing={1}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 3, px: 1, width: "100%" }}>
                <Button variant="contained" size="small" onClick={handleSubmit(publishPost)}>
                  Publish
                </Button>
                <Button variant="contained" size="small" onClick={handleSubmit(savePost)}>
                  Save As Draft
                </Button>
              </Stack>
            </CardContent>
          </Card>

          {/* EDITOR */}

          <Typography
            sx={{ my: 3 }}
            fontWeight={800}
            fontSize="1.2rem"
            textAlign={{ md: "center" }}>
            Content
          </Typography>

          <Editor
            holder="content-holder"
            onInitialize={handleInitialize}
            data={blogData && JSON.parse(blogData?.body)}
            tools={{
              header: Headers,
              list: {
                class: List,
                inlineToolbar: true,
                config: {
                  defaultStyle: "unordered",
                },
              },
              image: {
                class: Images,
                config: {
                  field: "blogImage",
                  uploader: {
                    uploadByFile(file) {
                      return uploadFile(file, "blogImage").then((data) => {
                        return {
                          success: 1,
                          file: {
                            url: data.blogImage,
                            // any other image data you want to store, such as width, height, color, extension, etc
                          },
                        };
                      });
                    },
                  },
                },
              },
              warning: Warnings,
            }}
            placeholder="Start typing here. . . . .">
            <Box id="content-holder" />
          </Editor>
        </>
      )}
    </Page>
  );
};

export default UpdateBlog;
