import { PostWithFileUpload } from "./request";

export const uploadFile = async (file, field) => {
  const formData = new FormData();
  formData.append(`${field}`, file, file.name);

  const response = await PostWithFileUpload("/admin/file", formData);
  if (response) {
    return response.fileUrls;
  } else {
    return null;
  }
};
