import React, { useState } from "react";
import {
  Stack,
  colors,
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Navigate, useParams } from "react-router-dom";

import AuthForm from "../../../components/dashboard/AuthForm";
import FormButton from "../../../components/global/FormButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthScreen from "../../../components/dashboard/AuthScreen";

import { ADMIN_DASHBOARD, ADMIN_LOGIN } from "../../../constants/routerUrls";
import { resetPasswordValidationSchema } from "../../../validation/auth";
import { resetPassword } from "../../../api/auth";

const AdminResetPassword = () => {
  const { recoveryToken } = useParams();
  const auth = useSelector((state) => state.auth);
  const [formFeedback, setFormFeedback] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const resolver = yupResolver(resetPasswordValidationSchema);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const response = await resetPassword(data, recoveryToken);
    if (response.data) {
      reset({ password: "", rePassword: "" });
      setFeedbackType("success");
      setFormFeedback(response.data);
    } else {
      setFeedbackType("error");
      setFormFeedback(response.error || "Failed to reset password!");
    }
  };

  /**
   * if user authenticated redirect to
   * dashboard
   */
  if (auth.isAuth) {
    return <Navigate to={ADMIN_DASHBOARD} replace={true} />;
  }

  return (
    <AuthScreen>
      <AuthForm
        message={formFeedback}
        heading="Reset Password"
        messageType={feedbackType}
        onSubmitCallback={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              name="password"
              variant="standard"
              label="New Password *"
              id="standard-required"
              type={showPassword ? "text" : "password"}
              error={errors?.password}
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.password?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              name="rePassword"
              variant="standard"
              id="standard-required"
              label="Confirm Password *"
              type={showRePassword ? "text" : "password"}
              error={errors?.rePassword}
              {...register("rePassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRePassword(!showRePassword)}>
                      {showRePassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.rePassword?.message}
            </FormHelperText>
          </Stack>
          <Stack pt={2} flexDirection="row" justifyContent="space-between" alignItems="center">
            <Link to={ADMIN_LOGIN}>Back to login</Link>
            <FormButton type="submit" isLoading={isSubmitting}>
              Reset Password
            </FormButton>
          </Stack>
        </Stack>
      </AuthForm>
    </AuthScreen>
  );
};

export default AdminResetPassword;
