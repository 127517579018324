import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testimonials: [],
};

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    setTestimonial: (state, action) => {
      state.testimonials = action.payload;
    },

    addTestimonial: (state, action) => {
      state.testimonials.unshift(action.payload);
    },

    updateTestimonial: (state, action) => {
      state.testimonials = state.testimonials.map((item) => {
        if (item.uid === action.payload.uid) {
          return action.payload;
        } else {
          return item;
        }
      });
    },

    removeTestimonial: (state, action) => {
      state.testimonials = state.testimonials.filter((item) => !action.payload.includes(item.uid));
    },
  },
});

export const testimonialAction = testimonialSlice.actions;
export default testimonialSlice.reducer;
