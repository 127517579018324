import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },

    addUser: (state, action) => {
      state.users.unshift(action.payload);
    },

    updateUser: (state, action) => {
      state.users = state.users.map((item) => {
        if (item.uid === action.payload.uid) {
          return action.payload;
        } else {
          return item;
        }
      });
    },

    removeUser: (state, action) => {
      state.users = state.users.filter((item) => !action.payload.includes(item.uid));
    },
  },
});

export const userAction = userSlice.actions;
export default userSlice.reducer;
