import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Add, Edit, Archive, Star } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, IconButton, Stack, Typography } from "@mui/material";

import Page from "../../../components/global/Page";
import NoDataOverlay from "../../../components/global/NoDataOverlay";

import { styles } from "../../../constants/styles";
import { fetchAllBlog, updateBlogFeatureList, updateBlogStatus } from "../../../api/blog";
import { ADD_NEW_BLOG, UPDATE_BLOG } from "../../../constants/routerUrls";

const BlogList = () => {
  const [blogList, setBlogList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [statusActionLoading, setStatusctionLoading] = useState([]);
  const [featureActionLoading, setFeatureActionLoading] = useState([]);

  const columns = [
    {
      field: "featuredImage",
      headerName: "Featured Image",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <Box component="img" src={params.value} width="80px" height="80px" borderRadius={1} />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "excerpt",
      headerName: "Excerpt",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "topic",
      headerName: "Topics",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "keywords",
      headerName: "Keywords",
      flex: 1,
      minWidth: 240,
    },
    {
      flex: 1,
      minWidth: 140,
      field: "isFeatured",
      headerName: "Featured Blog?",
      renderCell: (params) => {
        if (featureActionLoading?.includes(params.row.id)) {
          return <CircularProgress size={25} />;
        } else {
          if (params.value) {
            return <Chip variant="outlined" color="info" label="Featured" size="small" />;
          } else {
            return <Chip variant="outlined" label="Not Featured" size="small" />;
          }
        }
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        if (statusActionLoading?.includes(params.row.id)) {
          return <CircularProgress size={25} />;
        } else {
          if (params.value === "PUBLISHED") {
            return <Chip variant="outlined" label="Published" color="success" size="small" />;
          } else if (params.value === "DRAFT") {
            return <Chip variant="outlined" label="Draft" color="warning" size="small" />;
          } else if (params.value === "ARCHIVED") {
            return <Chip variant="outlined" label="Archived" color="error" size="small" />;
          } else {
            return <Chip variant="outlined" label={params.value} size="small" />;
          }
        }
      },
    },
    {
      flex: 1,
      minWidth: 80,
      field: "actions",
      headerName: "Action",
      renderCell: (params) => (
        <IconButton component={Link} to={`${UPDATE_BLOG}/${params?.row?.uid}`}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  const fetchData = async () => {
    setDataLoading(true);
    const blogs = await fetchAllBlog();
    setBlogList(blogs);
    setDataLoading(false);
  };

  useEffect(() => {
    (async () => fetchData())();
  }, []);

  const handleBlogStatusUpdate = async () => {
    const archivedList = blogList.filter((item) => selectedBlogs.includes(item.id));
    setStatusctionLoading(archivedList?.map((item) => item.id));
    const response = await updateBlogStatus(archivedList);
    if (response) {
      const updatedBlog = blogList.map((item) => {
        if (selectedBlogs.includes(item.id)) {
          return {
            ...item,
            status: item.status === "ARCHIVED" ? "DRAFT" : "ARCHIVED",
          };
        } else {
          return item;
        }
      });
      setBlogList(updatedBlog);
      setStatusctionLoading([]);
    }
  };

  const handleIsfeaturedStatusUpdate = async () => {
    const featureList = blogList.filter((item) => selectedBlogs.includes(item.id));
    setFeatureActionLoading(featureList?.map((item) => item.id));
    const response = await updateBlogFeatureList(featureList);
    if (response) {
      const updatedBlog = blogList.map((item) => {
        if (selectedBlogs.includes(item.id)) {
          return {
            ...item,
            isFeatured: !item.isFeatured,
          };
        } else {
          return item;
        }
      });

      setBlogList(updatedBlog);
      setFeatureActionLoading([]);
    }
  };

  return (
    <Page>
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <Typography {...styles.text.adminPageHeader}>Blogs</Typography>
        <Stack direction="row" flexWrap="wrap-reverse">
          {selectedBlogs?.length > 0 && (
            <Button
              size="small"
              color="error"
              variant="outlined"
              startIcon={<Archive />}
              sx={{ m: 1, width: 110 }}
              onClick={handleBlogStatusUpdate}>
              Archive
            </Button>
          )}
          {selectedBlogs?.length > 0 && (
            <Button
              size="small"
              color="success"
              variant="outlined"
              startIcon={<Star />}
              sx={{ m: 1, width: 110 }}
              onClick={handleIsfeaturedStatusUpdate}>
              Feature
            </Button>
          )}
          <Link to={ADD_NEW_BLOG}>
            <Button
              size="small"
              variant="contained"
              startIcon={<Add />}
              sx={{ m: 1, width: 110, color: "#fff" }}>
              Add New
            </Button>
          </Link>
        </Stack>
      </Stack>
      <Box height="450px" my={3}>
        <DataGrid
          rows={blogList}
          columns={columns}
          checkboxSelection
          loading={dataLoading}
          onSelectionModelChange={(item) => setSelectedBlogs(item)}
          components={{ NoResultsOverlay: NoDataOverlay, NoRowsOverlay: NoDataOverlay }}
        />
      </Box>
    </Page>
  );
};

export default BlogList;
