import React from "react";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";

export const ModeContext = React.createContext({ toggleColorMode: () => {} });
const ModeSwitch = () => {
  const theme = useTheme();
  const mode = React.useContext(ModeContext);

  return (
    <IconButton onClick={mode.toggleColorMode} color="inherit">
      {theme.palette.mode === "dark" ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
  );
};

export default ModeSwitch;
