import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, colors, TextField, FormHelperText } from "@mui/material";

import AuthForm from "../../../components/dashboard/AuthForm";
import FormButton from "../../../components/global/FormButton";
import AuthScreen from "../../../components/dashboard/AuthScreen";

import { recoverAccount } from "../../../api/auth";
import { recoveryValidationSchema } from "../../../validation/auth";
import { ADMIN_DASHBOARD, ADMIN_LOGIN } from "../../../constants/routerUrls";

const AdminForgotPassword = () => {
  const auth = useSelector((state) => state.auth);
  const [feedbackType, setFeedbackType] = useState("info");
  const [formFeedback, setFormFeedback] = useState(
    "Please, enter the email associated with your admin account.",
  );

  const resolver = yupResolver(recoveryValidationSchema);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const response = await recoverAccount(data);
    if (response.data) {
      reset({ email: "" });
      setFeedbackType("info");
      setFormFeedback(response.data);
    } else {
      setFeedbackType("error");
      setFormFeedback(response.error || "Request failed!");
    }
  };

  /**
   * if user authenticated redirect to
   * dashboard
   */
  if (auth.isAuth) {
    return <Navigate to={ADMIN_DASHBOARD} replace={true} />;
  }

  return (
    <AuthScreen>
      <AuthForm
        message={formFeedback}
        messageType={feedbackType}
        heading="Account Recovery"
        subHeading="Reset password to recover your account"
        onSubmitCallback={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              name="email"
              type="email"
              label="Email *"
              variant="standard"
              id="standard-required"
              error={errors?.email}
              {...register("email")}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.email?.message}
            </FormHelperText>
          </Stack>
          <Stack pt={2} flexDirection="row" justifyContent="space-between" alignItems="center">
            <Link to={ADMIN_LOGIN}>Back to login</Link>
            <FormButton type="submit" isLoading={isSubmitting}>
              Recover Account
            </FormButton>
          </Stack>
        </Stack>
      </AuthForm>
    </AuthScreen>
  );
};

export default AdminForgotPassword;
