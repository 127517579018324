import React, { useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Section from "../../widgets/Section";
import { MarkEmailRead, Unsubscribe as UnsubscribeIcon } from "@mui/icons-material";
import { useEffect } from "react";
import { fetchSubscription, toggleSubscription } from "../../api/contact";
import FormButton from "../../components/global/FormButton";

const Unsubscribe = () => {
  const { uid } = useParams();
  const [sub, setSub] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    if (uid) {
      setPageLoader(true);
      (async () => {
        const response = await fetchSubscription(uid);
        setSub(response.data);
      })();
      setPageLoader(false);
    }
  }, [uid]);

  const handleSubscription = async () => {
    setBtnLoader(true);
    const response = await toggleSubscription(uid, !sub);
    setSub(response.data);
    setBtnLoader(false);
  };

  return (
    <Section minHeight="46.5vh">
      <Stack p={2} mx="auto" alignItems="center" spacing={2} maxWidth="600px">
        {pageLoader ? (
          <Box p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography
              mb={2}
              fontSize="1.2rem"
              fontWeight={600}
              letterSpacing={2}
              textAlign="center">
              {sub
                ? "Are you sure you want to unscubscribe from all R-Squared emails?"
                : ":(  We are sorry to see you go..."}
            </Typography>
            <FormButton
              variant="contained"
              isLoading={btnLoader}
              onClick={handleSubscription}
              sx={{ textTransform: "capitalize" }}
              startIcon={sub ? <UnsubscribeIcon /> : <MarkEmailRead />}>
              {sub ? "Unsubscribe" : "Re-Subscribe"}
            </FormButton>
          </>
        )}
      </Stack>
    </Section>
  );
};

export default Unsubscribe;
