import React from "react";
import { Button, CircularProgress, Stack } from "@mui/material";

const FormButton = ({
  isLoading,
  isDisabled,
  loaderElem = (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        color: "white",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <CircularProgress size={18} color="inherit" />
    </Stack>
  ),
  children = "Submit",
  variant = "contained",
  sx = { minWidth: "120px", minHeight: "36.5px" },
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      disabled={isLoading || isDisabled}
      sx={{
        ...sx,
        position: "relative",
      }}
      {...rest}>
      {children}
      {isLoading && loaderElem}
    </Button>
  );
};

export default FormButton;
