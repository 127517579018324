import { Get, Patch, PatchWithFileUpload, Post, PostWithFileUpload } from "./request";

/* ADMIN */
export const getAllTestimonials = async () => {
  const response = await Get("/admin/testimonial/collection");
  if (response?.status === 200) {
    return response?.testimonials;
  } else {
    return [];
  }
};

export const createNewTestimonial = async ({
  url,
  name,
  message,
  designation,
  organization,
  testimonialImage,
}) => {
  const formData = new FormData();

  formData.append("url", url);
  formData.append("name", name);
  formData.append("designation", designation);
  formData.append("organization", organization);
  formData.append("message", message);
  if (testimonialImage) {
    formData.append("testimonialImage", testimonialImage, testimonialImage?.name);
  }

  const response = await PostWithFileUpload("/admin/testimonial/", formData);
  return response.newTestimonial;
};

export const updateTestimonialImage = async ({ testimonialImage, uid }) => {
  const formData = new FormData();

  formData.append("testimonialImage", testimonialImage, testimonialImage.name);

  const response = await PatchWithFileUpload("/admin/file/", formData, { uid });
  return response;
};

export const updateExistingTestimonial = async (data, uid) => {
  const response = await Patch("/admin/testimonial/", data, { uid });
  return response.updatedTestimonial;
};

export const deleteExistingTestimonial = async (formData) => {
  const response = await Post("/admin/testimonial/delete", formData);
  if (response?.status === 200) {
    return response.successList;
  } else {
    return null;
  }
};

/* CLIENT */
export const fetchAllTestimonialsPublicSide = async () => {
  const response = await Get("/testimonial");
  if (response.status === 200) {
    return response.testimonials;
  } else {
    return [];
  }
};
