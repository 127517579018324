export const ROOT = "/";
export const BLOG = "/blog";
export const UNSUBSCRIBE = "/unsubscribe";

export const ADMIN_ROOT = "/admin";
export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_DASHBOARD = "/admin/dashboard";
export const ADMIN_FORGOT_PASSWORD = "/admin/forgot-password";
export const ADMIN_RESET_PASSWORD = "/admin/reset-password";

export const ADMIN_BLOGS = "/admin/blogs";
export const ADD_NEW_BLOG = ADMIN_BLOGS + "/new";
export const UPDATE_BLOG = ADMIN_BLOGS + "/update";

export const ADMIN_SERVICES = "/admin/services";

export const ADMIN_TESTIMONIALS = "/admin/testimonials";

export const ADMIN_USERS = "/admin/users";
