import axios from "./axios";
import { store } from "../store";
import { feedbackActions } from "../store/reducers/feedbackReducer";

export const Get = async (url, query) => {
  try {
    const response = await axios.get(url, { params: query });
    return response?.data;
  } catch (error) {
    store.dispatch(
      feedbackActions.NOTIFY({
        message: error?.response?.data?.msg || "Request failed",
        status: "error",
      }),
    );
    return error?.response?.data;
  }
};

export const Post = async (url, body) => {
  try {
    const response = await axios.post(url, { ...body });
    return response?.data;
  } catch (error) {
    store.dispatch(
      feedbackActions.NOTIFY({
        message: error?.response?.data?.msg || "Request failed",
        status: "error",
      }),
    );
    return error?.response?.data;
  }
};

export const Patch = async (url, body, query) => {
  try {
    const response = await axios.patch(url, { ...body }, { params: query });
    return response?.data;
  } catch (error) {
    store.dispatch(
      feedbackActions.NOTIFY({
        message: error?.response?.data?.msg || "Request failed",
        status: "error",
      }),
    );
    return error?.response?.data;
  }
};

export const Delete = async (url) => {
  try {
    const response = await axios.delete(url);
    return response?.data;
  } catch (error) {
    store.dispatch(
      feedbackActions.NOTIFY({
        message: error?.response?.data?.msg || "Request failed",
        status: "error",
      }),
    );
    return error?.response?.data;
  }
};

export const PostWithFileUpload = async (url, formData, uploadEvent) => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: uploadEvent,
    });

    return response?.data;
  } catch (error) {
    store.dispatch(
      feedbackActions.NOTIFY({
        message: error?.response?.data?.msg || "Request failed",
        status: "error",
      }),
    );
    return error?.response?.data;
  }
};

export const PatchWithFileUpload = async (url, formData, query, uploadEvent) => {
  try {
    const response = await axios.patch(url, formData, {
      params: query,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: uploadEvent,
    });

    return response?.data;
  } catch (error) {
    store.dispatch(
      feedbackActions.NOTIFY({
        message: error?.response?.data?.msg || "Request failed",
        status: "error",
      }),
    );
    return error?.response?.data;
  }
};
