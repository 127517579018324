import React from "react";
import { Box } from "@mui/material";

const Section = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      width="100%"
      boxSizing="border-box"
      paddingTop={{ xl: "85px", lg: "75px", md: "75px", sm: "75px", xs: "75px" }}>
      {children}
    </Box>
  );
};

export default Section;
