import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const styles = {
  pt: 2,
  px: 4,
  pb: 3,
  top: "50%",
  left: "50%",
  width: "95%",
  maxWidth: 600,
  boxShadow: 24,
  overflow: "auto",
  maxHeight: "75vh",
  bgcolor: "#272727",
  position: "absolute",
  border: "2px solid #000",
  transform: "translate(-50%, -50%)",
};

const FormModal = ({
  isOpen,
  onClose,
  children,
  formModalStyles,
  onSubmitCallback,
  title = "Form Modal",
}) => {
  return (
    <Modal open={isOpen}>
      <Box
        component="form"
        onSubmit={onSubmitCallback}
        sx={{ ...styles, formModalStyles, position: "relative" }}>
        <IconButton sx={{ position: "absolute", right: 10, top: 10 }} onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant="h5" mb={1}>
          {title}
        </Typography>
        <Divider />
        <Box py={2}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default FormModal;
