import * as yup from "yup";
import { imageValidation } from "../utils/yupUtils";

const URL_REGEX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const urlValidation = yup
  .string()
  .matches(URL_REGEX, "invalid url!")
  .transform((cv, ov) => {
    return ov === "" ? null : cv;
  })
  .nullable();

export const testimonialValidationSchema = yup.object().shape({
  name: yup.string().required("name is a required field"),
  message: yup.string(),
  designation: yup.string(),
  organization: yup.string(),
  url: urlValidation,
  testimonialImage: imageValidation.optional,
});

export const deleteTestimonialValidationSchema = yup.object().shape({
  password: yup.string().required("password is a required field"),
});
