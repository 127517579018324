import React from "react";
import { motion } from "framer-motion";
import { Box, Button, Container, Stack, Typography } from "@mui/material";

const MotionButton = motion(Button);
const MotionText = motion(Typography);

const Banner2 = () => {
  return (
    <Container maxWidth="lg" id="banner">
      <Box boxSizing="border-box" px={2} py={2}>
        <Stack
          spacing={4}
          width="100%"
          alignItems="center"
          justifyContent="center"
          animate={{ opacity: [0, 1], y: [80, 0] }}
          sx={{ height: "auto", minHeight: "60vh" }}
          transition={{ duration: 0.8, type: "spring" }}>
          <MotionText
            lineHeight={1}
            fontWeight="600"
            textAlign="center"
            animate={{ opacity: [0, 1], y: [80, 0] }}
            transition={{ duration: 0.8, type: "spring" }}
            fontSize={{ xl: "2.4rem", lg: "2.2rem", md: "2rem", sm: "1.8rem", xs: "1.6rem" }}>
            Hello, We&apos;re
          </MotionText>
          <MotionText
            lineHeight={0.5}
            fontWeight="600"
            letterSpacing={3}
            textAlign="center"
            whiteSpace="noWrap"
            animate={{ opacity: [0, 1], y: [80, 0] }}
            transition={{ duration: 0.8, type: "spring", delay: 0.25 }}
            fontSize={{ xl: "3.4rem", lg: "3.2rem", md: "3rem", sm: "2.8rem", xs: "2.6rem" }}>
            R Squared
          </MotionText>
          <MotionText
            fontWeight="500"
            textAlign="center"
            animate={{ opacity: [0, 1], y: [80, 0] }}
            transition={{ duration: 0.8, type: "spring", delay: 0.5 }}
            fontSize={{ xl: "1.2rem", lg: "1.2rem", md: "1.2rem", sm: "1rem", xs: "1rem" }}
            sx={{ maxWidth: { xl: "75%", lg: "75%", md: "85%", sm: "90%", xs: "95%" } }}>
            A team of data driven business decision makers, data scientists and engineers working as
            strategists, who will look into your overall business model and work.
          </MotionText>
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <MotionButton
              size="large"
              component="a"
              href="#contact"
              variant="contained"
              className="link-buttons"
              animate={{ opacity: [0, 1], y: [80, 0] }}
              transition={{ duration: 0.8, type: "spring", delay: 1 }}
              sx={{
                fontWeight: 600,
                letterSpacing: 2,
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
                py: { xl: 1.5, lg: 1.5, md: 1 },
                px: { xl: 4, lg: 4, md: 3, sm: 2 },
              }}>
              Let&apos;s Chat
            </MotionButton>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default Banner2;
