import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import RootRoute from "../routes/RootRoute";
import { ModeContext } from "../themes/mode";

const ThemeSetup = () => {
  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [],
  );

  const theme = React.useMemo(() =>
    createTheme({
      palette: {
        mode,

        primary: {
          main: "#0057fc",
          dark: "#0057fc",
        },

        secondary: {
          main: "#0057fc",
          dark: "#0057fc",
        },
      },
      typography: {
        fontFamily: "'Encode Sans', sans-serif",
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 0,
              boxShadow: ["none"],
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: ["none"],
            },
          },
        },
      },
    }),
  );

  return (
    <ModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootRoute />
      </ThemeProvider>
    </ModeContext.Provider>
  );
};

export default ThemeSetup;
