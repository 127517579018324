import * as yup from "yup";

const IMAGE_SIZE_LIMIT_IN_BYTES = 5 * 1024 * 1024; // 5 mb in bytes
const SUPPORTED_FILE_FORMATS = ["image/svg", "image/jpg", "image/jpeg", "image/png"];

export const imageValidation = {
  optional: yup
    .mixed()
    .notRequired()
    .default(null)
    .test("fileSize", "the file is too large, keep it within 5MB", (file) =>
      file?.length ? file?.[0]?.size <= IMAGE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      "fileType",
      "this file type is not allowed, use a jpg, jpeg, png or svg file instead",
      (file) => (file?.length ? SUPPORTED_FILE_FORMATS.includes(file?.[0]?.type) : true),
    ),
  required: yup
    .mixed()
    .test("file", "this file is required", (file) => (file.length ? file.length > 0 : false))
    .test("fileSize", "the file is too large, keep it within 5MB", (file) =>
      file.length ? file[0].size <= IMAGE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      "fileType",
      "this file type is not allowed, use a jpg, jpeg, png or svg file instead",
      (file) => (file.length ? SUPPORTED_FILE_FORMATS.includes(file[0].type) : true),
    ),
};
