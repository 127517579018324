/* eslint-disable react/react-in-jsx-scope */
import * as routerUrls from "./routerUrls";
import { Insights, RssFeed, CardMembership, Dashboard, Group } from "@mui/icons-material";
import { adminRoles } from "./auth";

export const adminSidebarList1 = [
  {
    label: "Dashboard",
    icon: <Dashboard style={{ fill: "white" }} />,
    path: routerUrls.ADMIN_DASHBOARD,
  },
  {
    label: "Blogs",
    icon: <RssFeed style={{ fill: "white" }} />,
    path: routerUrls.ADMIN_BLOGS,
  },
  {
    label: "Services",
    icon: <Insights style={{ fill: "white" }} />,
    path: routerUrls.ADMIN_SERVICES,
  },
  {
    label: "Testimonials",
    icon: <CardMembership style={{ fill: "white" }} />,
    path: routerUrls.ADMIN_TESTIMONIALS,
  },
];

export const adminSidebarList2 = [
  {
    label: "Users",
    icon: <Group style={{ fill: "white" }} />,
    path: routerUrls.ADMIN_USERS,
    [adminRoles.ADMIN]: false,
    [adminRoles.SUPERADMIN]: true,
  },
];
