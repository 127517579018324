import { store } from "../store";
import { slugify } from "../utils/slugify";
import { feedbackActions } from "../store/reducers/feedbackReducer";
import { Get, Patch, PatchWithFileUpload, PostWithFileUpload } from "./request";

/* Admin APIs */

export const fetchAllBlog = async () => {
  const response = await Get("/admin/blog/collection");
  if (response) {
    return response.blogs;
  } else {
    return [];
  }
};

export const fetchBlog = async (uid) => {
  const response = await Get("/admin/blog/", { uid });
  if (response.status === 200) {
    return response.blog;
  } else {
    return null;
  }
};

export const fetchTopicSuggestions = async (topic) => {
  const response = await Get("admin/blog/topics", { topic });

  if (response) {
    return response.topics;
  } else {
    return [];
  }
};

export const fetchkeywordSuggestions = async (keyword) => {
  const response = await Get("admin/blog/keywords", { keyword });

  if (response) {
    return response.keywords;
  } else {
    return [];
  }
};

export const createNewBlog = async ({
  body,
  title,
  topic,
  author,
  status,
  excerpt,
  keywords,
  aboutAuthor,
  authorProfile,
  blogAuthorImage,
  featuredBlogImage,
}) => {
  const formData = new FormData();
  const slug = slugify(title);

  formData.append("blogAuthorImage", blogAuthorImage, blogAuthorImage.name);
  formData.append("featuredBlogImage", featuredBlogImage, featuredBlogImage.name);
  formData.append("title", title);
  formData.append("slug", slug);

  formData.append("topic", topic);
  formData.append("author", author);
  formData.append("status", status);
  formData.append("excerpt", excerpt);
  formData.append("keywords", keywords);
  formData.append("aboutAuthor", aboutAuthor);
  formData.append("authorProfile", authorProfile);
  formData.append("body", JSON.stringify(body));

  const response = await PostWithFileUpload("/admin/blog", formData);
  if (response.status === 200) {
    store.dispatch(feedbackActions.NOTIFY({ status: "success", message: "Blog post added" }));
    return true;
  } else {
    return false;
  }
};

export const updateFeaturedBlogImage = async ({ featuredBlogImage, uid }) => {
  const formData = new FormData();

  formData.append("featuredBlogImage", featuredBlogImage, featuredBlogImage.name);

  const response = await PatchWithFileUpload("/admin/file/", formData, { uid });
  return response;
};

export const updateBlogAuthorImage = async ({ blogAuthorImage, uid }) => {
  const formData = new FormData();

  formData.append("blogAuthorImage", blogAuthorImage, blogAuthorImage.name);

  const response = await PatchWithFileUpload("/admin/file/", formData, { uid });
  return response;
};

export const updateBlog = async ({
  uid,
  title,
  excerpt,
  topic,
  keywords,
  author,
  aboutAuthor,
  authorProfile,
  body,
  status,
}) => {
  const slug = slugify(title);
  const updateData = {
    title,
    slug,
    excerpt,
    topic,
    keywords,
    author,
    aboutAuthor,
    authorProfile,
    body: JSON.stringify(body),
    status,
  };

  const response = await Patch("/admin/blog/", updateData, { uid });
  if (response.status === 200) {
    store.dispatch(feedbackActions.NOTIFY({ status: "success", message: "Blog post updated" }));
    return true;
  } else {
    return false;
  }
};

export const updateBlogFeatureList = async (featuredList) => {
  const response = await Patch("/admin/blog/featured", { featuredList });
  if (response) {
    store.dispatch(feedbackActions.NOTIFY({ status: "success", message: "Status updated" }));
    return true;
  } else {
    return false;
  }
};

export const updateBlogStatus = async (archivedList) => {
  const response = await Patch("/admin/blog/archived", { archivedList });
  if (response) {
    store.dispatch(feedbackActions.NOTIFY({ status: "success", message: "Status updated" }));
    return true;
  } else {
    return false;
  }
};

/* Public APIs */

export const fetchFeaturedBlogs = async () => {
  const response = await Get("/blog/featured");

  if (response) {
    return response.blogs;
  } else {
    return [];
  }
};

export const fetchAllPublicBlogs = async () => {
  const response = await Get("/blog");
  if (response) {
    return response.blogs;
  } else {
    return [];
  }
};

export const fetchBlogBySlug = async (slug) => {
  const response = await Get(`/blog/post/${slug}`);
  if (response.status === 200) {
    return response.blog;
  } else {
    return null;
  }
};

export const fetchSuggestion = async (query) => {
  const response = await Get("/blog/suggestion", { name: query });

  if (response) {
    return response.blogs;
  } else {
    return [];
  }
};

export const fetchBlogs = async (query) => {
  const response = await Get("/blog/collection", { name: query.name, page: query.page });

  if (response) {
    return {
      blogs: response.blogs,
      total: response.total,
    };
  } else {
    return null;
  }
};

export const fetchDraftBlogs = async () => {
  const response = await Get("/admin/blog/draft");

  if (response) {
    return response.blogs;
  } else {
    return [];
  }
};
