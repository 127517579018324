import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "../constants/routes";
import AdminLayout from "../layout/AdminLayout";
import CommonLayout from "../layout/CommonLayout";
import SiteLayout from "../layout/SiteLayout";
import Protected from "../widgets/Protected";

const RootRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => {
          const { Element, index, isProtected, isPublic, path, seo, ...rest } = route;

          if (isProtected) {
            return (
              <Route element={<AdminLayout />} key={path}>
                <Route
                  index={index}
                  path={path}
                  element={
                    <Protected {...rest}>
                      <Element />
                    </Protected>
                  }
                />
              </Route>
            );
          } else {
            if (isPublic) {
              return (
                <Route element={<SiteLayout />} key={path}>
                  <Route index={index} path={path} element={<Element seo={seo} />} />
                </Route>
              );
            } else {
              return (
                <Route element={<CommonLayout />} key={path}>
                  <Route index={index} path={path} element={<Element seo={seo} />} />
                </Route>
              );
            }
          }
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoute;
