import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowCircleRight } from "@mui/icons-material";
import { Box, Button, colors, Skeleton, Stack, Typography } from "@mui/material";

import { BLOG } from "../../constants/routerUrls";
import { fetchFeaturedBlogs } from "../../api/blog";

const FeaturedBlogs = () => {
  const [loader, setLoader] = useState(true);
  const [isCardHovered, setIsCardHovered] = useState("");
  const [featuredBlogs, setFeaturedBlogs] = useState([...Array(3)]);

  const fetchData = async () => {
    setLoader(true);
    const blog = await fetchFeaturedBlogs();
    setFeaturedBlogs(blog);
    setLoader(false);
  };

  useEffect(() => {
    (async () => fetchData())();
  }, []);

  return (
    <Stack direction="row" width="100%" flexWrap="wrap" justifyContent="center">
      {featuredBlogs?.map((item, index) => (
        <Box
          height="450px"
          component={Link}
          position="relative"
          className="blog-links"
          key={item?.uid || index}
          bgcolor={colors.grey[900]}
          to={`${BLOG}/${item?.slug}`}
          onMouseLeave={() => setIsCardHovered("")}
          onMouseEnter={() => setIsCardHovered(item.uid)}
          width={{ xl: "33%", lg: "33.33%", md: "50%", sm: "100%", xs: "100%" }}>
          <Box width="100%" height="100%" overflow="hidden" position="absolute" top="0">
            <Box
              width="100%"
              height="100%"
              component="img"
              src={item?.featuredImage}
              sx={{
                overflow: "hidden",
                transition: "0.5s ease",
                transform: isCardHovered === item?.uid ? "scale(1.2)" : "scale(1.01)",
              }}
            />
          </Box>
          <Box
            top="0"
            width="100%"
            height="100%"
            position="absolute"
            sx={{
              background:
                "linear-gradient(187deg, rgba(30,30,42,0.47692580450148814) 0%, rgba(29,23,42,1) 100%)",
            }}
          />
          <Box
            px={2}
            py={2}
            top="0"
            width="100%"
            height="100%"
            position="absolute"
            boxSizing="border-box">
            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
              <Typography
                fontSize={{
                  sm: "2rem",
                  xs: "1.6rem",
                }}
                fontWeight={600}>
                {loader ? (
                  <>
                    <Skeleton />
                    <Skeleton width="45%" />
                  </>
                ) : (
                  `${item?.title?.substring(0, 64)}${item?.title?.length > 64 ? "..." : ""}`
                )}
              </Typography>
              <Stack spacing={3}>
                <Typography fontSize="1rem" sx={{ maxWidth: "85%" }}>
                  {loader ? (
                    <>
                      <Skeleton />
                      <Skeleton width="75%" />
                      <Skeleton width="85%" />
                    </>
                  ) : (
                    `${item?.excerpt?.substring(0, 256)}${item?.excerpt?.length > 256 ? "..." : ""}`
                  )}
                </Typography>
                {loader ? (
                  <Skeleton width="25%" height={50} />
                ) : (
                  <Button
                    sx={{
                      fontWeight: 700,
                      letterSpacing: 1,
                      width: "max-content",
                      textTransform: "capitalize",
                    }}
                    endIcon={<ArrowCircleRight />}>
                    Read More
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default FeaturedBlogs;
