import { Get, Patch, PatchWithFileUpload, Post, PostWithFileUpload } from "./request";

/* ADMIN */
export const getAllServices = async () => {
  const response = await Get("/admin/service/collection");
  if (response?.status === 200) {
    return response?.services;
  } else {
    return [];
  }
};

export const createNewService = async ({ serviceImage, title, description }) => {
  const formData = new FormData();

  formData.append("title", title);
  formData.append("description", description);
  formData.append("serviceImage", serviceImage, serviceImage.name);

  const response = await PostWithFileUpload("/admin/service/", formData);
  return response.newService;
};

export const updateServiceImage = async ({ serviceImage, uid }) => {
  const formData = new FormData();

  formData.append("serviceImage", serviceImage, serviceImage.name);

  const response = await PatchWithFileUpload("/admin/file/", formData, { uid });
  return response;
};

export const updateExistingService = async (data, uid) => {
  const response = await Patch("/admin/service/", data, { uid });
  return response.updatedService;
};

export const deleteExistingService = async (formData) => {
  const response = await Post("/admin/service/delete", formData);
  if (response?.status === 200) {
    return response.successList;
  } else {
    return null;
  }
};

/* CLIENT */
export const fetchAllServicesPublicSide = async () => {
  const response = await Get("/service");
  if (response.status === 200) {
    return response.services;
  } else {
    return [];
  }
};
