import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import FeedbackNotification from "../components/global/FeedbackNotification";

const CommonLayout = () => {
  return (
    <Box>
      <Outlet />
      <FeedbackNotification />
    </Box>
  );
};

export default CommonLayout;
