import { Get } from "./request";

export const getDashboardStats = async () => {
  const response = await Get("/admin/stats/dashboard");
  if (response) {
    return response.stats;
  } else {
    return [];
  }
};
