import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload;
    },

    addService: (state, action) => {
      state.services.unshift(action.payload);
    },

    updateService: (state, action) => {
      state.services = state.services.map((item) => {
        if (item.uid === action.payload.uid) {
          return action.payload;
        } else {
          return item;
        }
      });
    },

    removeService: (state, action) => {
      state.services = state.services.filter((item) => !action.payload.includes(item.uid));
    },
  },
});

export const serviceAction = serviceSlice.actions;
export default serviceSlice.reducer;
