import { Get, Patch, Post } from "./request";

export const contactRsquared = async (data) => {
  const response = await Post("/contact/", data);
  if (response?.status === 200) {
    return { data: response.msg, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};

export const subToNewsletter = async (data) => {
  const response = await Post("/contact/subscribe", data);
  if (response?.status === 200) {
    return { data: response.msg, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};

export const fetchSubscription = async (uid) => {
  const response = await Get("/contact/subscription", { uid });
  if (response?.status === 200) {
    return { data: response.isSubscribed, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};

export const toggleSubscription = async (uid, sub) => {
  const response = await Patch("/contact/unsubscribe", {}, { uid, sub });
  if (response?.status === 200) {
    return { data: response.isSubscribed, error: null };
  } else {
    return { data: null, error: response.msg };
  }
};
