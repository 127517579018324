import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Stack,
  colors,
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

import AuthForm from "../../../components/dashboard/AuthForm";
import FormButton from "../../../components/global/FormButton";
import AuthScreen from "../../../components/dashboard/AuthScreen";

import { loginUser } from "../../../api/auth";
import { loginValidationSchema } from "../../../validation/auth";
import { authActions } from "../../../store/reducers/authReducer";
import { feedbackActions } from "../../../store/reducers/feedbackReducer";
import { ADMIN_DASHBOARD, ADMIN_FORGOT_PASSWORD } from "../../../constants/routerUrls";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [formFeedback, setFormFeedback] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const resolver = yupResolver(loginValidationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const response = await loginUser(data);
    if (response.data) {
      setFormFeedback("");
      dispatch(authActions.LOGIN({ user: response.data.user, token: response.data.token }));
      dispatch(
        feedbackActions.NOTIFY({
          status: "success",
          message: `Welcome ${response.data.user.name}`,
        }),
      );
    } else {
      setFormFeedback(response.error || "Failed to login! Try again.");
    }
  };

  /**
   * if user authenticated redirect to
   * dashboard
   */
  if (auth.isAuth) {
    return <Navigate to={ADMIN_DASHBOARD} replace={true} />;
  }

  return (
    <AuthScreen>
      <AuthForm
        heading="Welcome"
        messageType="error"
        message={formFeedback}
        subHeading="Login to R-Squared admin portal"
        onSubmitCallback={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              name="email"
              label="Email *"
              variant="standard"
              id="standard-required"
              error={errors?.email}
              {...register("email")}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.email?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              name="password"
              label="Password *"
              variant="standard"
              autoComplete="current-password"
              id="standard-password-input-required"
              type={showPassword ? "text" : "password"}
              error={errors?.password}
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.password?.message}
            </FormHelperText>
          </Stack>
          <Stack pt={2} flexDirection="row" justifyContent="space-between" alignItems="center">
            <Link to={ADMIN_FORGOT_PASSWORD}>Forgot password?</Link>
            <FormButton type="submit" isLoading={isSubmitting}>
              Login
            </FormButton>
          </Stack>
        </Stack>
      </AuthForm>
    </AuthScreen>
  );
};

export default AdminLogin;
