import * as yup from "yup";
import { imageValidation } from "../utils/yupUtils";

export const createServiceValidationSchema = yup.object().shape({
  title: yup
    .string()
    .max(64, "title can not exceed 64 characters")
    .required("title is a required field"),
  description: yup.string().required("description is a required field"),
  serviceImage: imageValidation.required,
});

export const updateServiceValidationSchema = yup.object().shape({
  title: yup
    .string()
    .max(64, "title can not exceed 64 characters")
    .required("title is a required field"),
  description: yup.string().required("description is a required field"),
  serviceImage: imageValidation.optional,
});

export const deleteServiceValidationSchema = yup.object().shape({
  password: yup.string().required("password is a required field"),
});
