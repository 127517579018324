import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Chip, IconButton, Skeleton, Stack, Typography } from "@mui/material";

import Page from "../../components/global/Page";
import NoDataOverlay from "../../components/global/NoDataOverlay";

import { fetchDraftBlogs } from "../../api/blog";
import { getDashboardStats } from "../../api/stats";
import { UPDATE_BLOG } from "../../constants/routerUrls";
import { styles } from "../../constants/styles";

const AdminDashboard = () => {
  const [stats, setStats] = useState([]);
  const [draftBlogs, setDraftBlogs] = useState([]);
  const [blogLoader, setBlogLoader] = useState(false);
  const [statsLoader, setStatsLoader] = useState(false);

  const fetchData = async () => {
    setBlogLoader(true);
    const response = await fetchDraftBlogs();
    setDraftBlogs(response);
    setBlogLoader(false);
  };
  const fetchStats = async () => {
    setStatsLoader(true);
    const response = await getDashboardStats();
    setStats(response);
    setStatsLoader(false);
  };

  useEffect(() => {
    (async () => await fetchData())();
    (async () => await fetchStats())();
  }, []);

  const columns = [
    {
      field: "featuredImage",
      headerName: "Featured Image",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <Box component="img" src={params.value} width="80px" height="80px" borderRadius={1} />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "excerpt",
      headerName: "Excerpt",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "keywords",
      headerName: "Keywords",
      flex: 1,
      minWidth: 240,
    },
    {
      flex: 1,
      minWidth: 120,
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        if (params.value === "PUBLISHED") {
          return <Chip variant="outlined" label="Published" color="success" size="small" />;
        } else if (params.value === "DRAFT") {
          return <Chip variant="outlined" label="Draft" color="warning" size="small" />;
        } else if (params.value === "ARCHIVED") {
          return <Chip variant="outlined" label="Archived" color="error" size="small" />;
        } else {
          return <Chip variant="outlined" label={params.value} size="small" />;
        }
      },
    },
    {
      flex: 1,
      minWidth: 80,
      field: "actions",
      headerName: "Action",
      renderCell: (params) => (
        <IconButton component={Link} to={`${UPDATE_BLOG}/${params?.row?.uid}`}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <Page>
      <Stack direction="row" flexWrap="wrap" mb={4}>
        {stats.map((stat, index) => (
          <Stack
            pb="10px"
            key={index}
            pr={{ sm: "10px", xs: "0" }}
            width={{ xl: "20%", lg: "25%", md: "33.33%", sm: "50%", xs: "100%" }}>
            <Stack
              p={2}
              width="100%"
              height="100%"
              bgcolor="#212121"
              justifyContent="space-between">
              <Typography fontSize="1.2rem" fontWeight={600}>
                {statsLoader ? <Skeleton /> : stat?.title}
              </Typography>
              <Typography fontSize="3rem" fontWeight={900}>
                {statsLoader ? <Skeleton width={80} /> : stat?.count}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Typography {...styles.text.adminPageHeader}>Unpublished Blogs</Typography>
      <Box height="450px" my={3}>
        <DataGrid
          columns={columns}
          rows={draftBlogs}
          loading={blogLoader}
          components={{ NoResultsOverlay: NoDataOverlay, NoRowsOverlay: NoDataOverlay }}
        />
      </Box>
    </Page>
  );
};

export default AdminDashboard;
