import { Box } from "@mui/material";
import React from "react";

const Page = ({ children, ...props }) => {
  return (
    <Box width="100%" height="auto" minHeight="80vh" {...props}>
      {children}
    </Box>
  );
};

export default Page;
