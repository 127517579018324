import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Stack,
  Alert,
  colors,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "../../../constants/styles";
import Page from "../../../components/global/Page";
import AlertModal from "../../../components/global/AlertModal";
import FormButton from "../../../components/global/FormButton";
import FormModal from "../../../components/dashboard/FormModal";
import NoDataOverlay from "../../../components/global/NoDataOverlay";
import { Add, Delete, Edit, Visibility, VisibilityOff } from "@mui/icons-material";

import {
  createUserValidationSchema,
  deleteUserValidationSchema,
  updateUserValidationSchema,
} from "../../../validation/user";
import {
  createNewAdminUser,
  deleteExistingAdminUser,
  getAllAdminUsers,
  updateExistingAdminUser,
} from "../../../api/users";
import { userAction } from "../../../store/reducers/userReducer";
import { feedbackActions } from "../../../store/reducers/feedbackReducer";

const User = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [formData, setFormData] = useState();
  const [activeForm, setActiveForm] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContetnt, setAlertContent] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const users = useSelector((state) => state.user.users);
  const authUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    (async () => {
      setDataLoading(true);
      const res = await getAllAdminUsers(authUser.uid);
      dispatch(userAction.setUsers(res));
      setDataLoading(false);
    })();
  }, []);

  const handleResolver = () => {
    switch (activeForm) {
      case "CREATE":
        return createUserValidationSchema;
      case "UPDATE":
        return updateUserValidationSchema;
      case "DELETE":
        return deleteUserValidationSchema;
      default:
        return null;
    }
  };

  const resolver = yupResolver(handleResolver());
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const name = useWatch({ control, name: "name" });
  const role = useWatch({ control, name: "role" });
  const email = useWatch({ control, name: "email" });
  const status = useWatch({ control, name: "status" });
  const password = useWatch({ control, name: "password" });

  const handleAddModalClose = () => {
    setAddModalOpen(false);
    reset({ name: "", email: "", role: "" });
  };

  const onCreateSubmit = (data) => {
    setFormData(data);
    setAlertContent(`Are you sure you want to add ${data?.name} as an admin?`);
    setAlertOpen(true);
  };

  const createUser = async () => {
    setFormLoading(true);
    const { name, email, role } = formData;
    const response = await createNewAdminUser({ name, role, email });
    if (response.data) {
      dispatch(userAction.addUser(response.data));
      dispatch(feedbackActions.NOTIFY({ status: "success", message: `${name} added as an admin` }));
      handleAddModalClose();
    } else {
      dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: response.error || "Failed to create admin user",
        }),
      );
    }
    setFormLoading(false);
  };

  const handleRowUpdate = (row) => {
    setRowData(row);
    setValue("name", row.name);
    setValue("email", row.email);
    setValue("role", row.role);
    setValue("status", row.status);
    setActiveForm("UPDATE");
    setUpdateModalOpen(true);
  };

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
    reset({ name: "", email: "", role: "", status: "" });
  };

  const detectUpdate = () => {
    let flag = true;
    if (
      rowData?.name !== name ||
      rowData?.email !== email ||
      rowData?.role !== role ||
      rowData?.status !== status
    ) {
      flag = false;
    }
    return flag;
  };

  const onUpdateSubmit = (data) => {
    setFormData(data);
    setAlertContent(`Are you sure you want to update ${data?.name}'s info?`);
    setAlertOpen(true);
  };

  const updateUser = async () => {
    setFormLoading(true);
    const { name, role, email, status } = formData;
    const response = await updateExistingAdminUser({ name, role, email, status }, rowData?.uid);
    if (response.data) {
      dispatch(userAction.updateUser(response.data));
      dispatch(feedbackActions.NOTIFY({ status: "success", message: `${name}'s info updated` }));
      handleUpdateModalClose();
    } else {
      dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: response.error || "Failed to update user info",
        }),
      );
    }
    setFormLoading(false);
  };

  const handleRowDelete = (row) => {
    setFormData({ deleteList: [row.uid] });
    setActiveForm("DELETE");
    setAlertContent(
      `Are you sure you want to delete ${row.name}? You can not revert this action! Alternatively, you can make the user 'Inactive'.`,
    );
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    reset({ password: "" });
  };

  const onDeleteSubmit = (data) => {
    setFormData({ ...formData, password: data.password });
    setAlertOpen(true);
  };

  const deleteUser = async () => {
    setFormLoading(true);
    const response = await deleteExistingAdminUser(formData);
    if (response) {
      dispatch(userAction.removeUser(response));
      dispatch(
        feedbackActions.NOTIFY({
          status: "success",
          message: "User was removed from the admin list",
        }),
      );
      handleDeleteModalClose();
    } else {
      dispatch(feedbackActions.NOTIFY({ status: "error", message: "Failed to delete user" }));
    }
    setFormLoading(false);
  };

  const columns = [
    {
      flex: 1,
      minWidth: 140,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 1,
      minWidth: 280,
      field: "email",
      headerName: "Email",
    },
    {
      width: 120,
      field: "role",
      headerName: "Role",
      renderCell: (params) => {
        if (params.value === "ADMIN") {
          return <Chip variant="outlined" label="Admin" size="small" />;
        } else {
          return <Chip variant="outlined" color="warning" label="Super admin" size="small" />;
        }
      },
    },
    {
      width: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        if (params.value === "ACTIVE") {
          return <Chip variant="outlined" color="success" label="Active" size="small" />;
        } else {
          return <Chip variant="outlined" color="error" label="Inactive" size="small" />;
        }
      },
    },
    {
      width: 120,
      field: "actions",
      headerName: "Action",
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton color="success" onClick={() => handleRowUpdate(params.row)}>
            <Edit />
          </IconButton>
          <IconButton color="error" onClick={() => handleRowDelete(params.row)}>
            <Delete />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const handleAlertCallback = () => {
    switch (activeForm) {
      case "CREATE":
        return createUser;
      case "UPDATE":
        return updateUser;
      case "DELETE":
        return deleteUser;
      default:
        return null;
    }
  };

  return (
    <Page>
      {/* ALERT MODAL */}
      <AlertModal
        title="Confirm"
        isOpen={alertOpen}
        content={alertContetnt}
        onClose={() => setAlertOpen(false)}
        onResolveClick={handleAlertCallback()}
      />

      {/* ADD FORM MODAL */}
      <FormModal
        title="Add New User"
        isOpen={addModalOpen}
        onClose={handleAddModalClose}
        onSubmitCallback={handleSubmit(onCreateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              label="Name"
              error={errors?.name}
              {...register("name")}
              placeholder="name of the user"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.name?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Email"
              error={errors?.email}
              {...register("email")}
              placeholder="email of the user"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.email?.message}
            </FormHelperText>
          </Stack>
          <FormControl error={errors?.role}>
            <InputLabel>Role</InputLabel>
            <Select label="Role" {...register("role")}>
              {[
                { name: "Admin", value: "ADMIN" },
                { name: "Super Admin", value: "SUPERADMIN" },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.role && (
              <FormHelperText sx={{ ml: 0 }}>{errors?.role?.message}</FormHelperText>
            )}
          </FormControl>

          <FormButton type="submit" isLoading={formLoading}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* UPDATE FORM MODAL */}
      <FormModal
        title="Update User Info"
        isOpen={updateModalOpen}
        onClose={handleUpdateModalClose}
        onSubmitCallback={handleSubmit(onUpdateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              label="Name"
              error={errors?.name}
              {...register("name")}
              placeholder="name of the user"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.name?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Email"
              error={errors?.email}
              {...register("email")}
              placeholder="email of the user"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.email?.message}
            </FormHelperText>
          </Stack>
          <FormControl error={errors?.role}>
            <InputLabel>Role</InputLabel>
            <Select defaultValue={role} label="Role" {...register("role")}>
              {[
                { name: "Admin", value: "ADMIN" },
                { name: "Super Admin", value: "SUPERADMIN" },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.role && (
              <FormHelperText sx={{ ml: 0 }}>{errors?.role?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors?.status}>
            <InputLabel>Status</InputLabel>
            <Select defaultValue={status} label="Status" {...register("status")}>
              {[
                { name: "Active", value: "ACTIVE" },
                { name: "Inactive", value: "INACTIVE" },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.status && (
              <FormHelperText sx={{ ml: 0 }}>{errors?.status?.message}</FormHelperText>
            )}
          </FormControl>

          <FormButton type="submit" isLoading={formLoading} isDisabled={detectUpdate()}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* DELETE FORM MODAL */}
      <FormModal
        title="Delete User"
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onSubmitCallback={handleSubmit(onDeleteSubmit)}>
        <Stack spacing={2}>
          <Alert severity="warning">Enter your password to confirm this action.</Alert>
          <Stack>
            <TextField
              name="password"
              label="Password *"
              variant="standard"
              autoComplete="current-password"
              id="standard-password-input-required"
              type={showPassword ? "text" : "password"}
              error={errors?.password}
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.password?.message}
            </FormHelperText>
          </Stack>
          <FormButton
            type="submit"
            isLoading={formLoading}
            isDisabled={!password || password?.length < 8}>
            Delete
          </FormButton>
        </Stack>
      </FormModal>

      {/* PARENT COMPONENT */}
      <Stack direction="row" alignItems="center" flexWrap="wrap" justifyContent="space-between">
        <Typography {...styles.text.adminPageHeader}>Users</Typography>
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setActiveForm("CREATE");
              setAddModalOpen(true);
            }}>
            Add New
          </Button>
        </Stack>
      </Stack>
      <Box height="450px" my={3}>
        <DataGrid
          rows={users}
          columns={columns}
          loading={dataLoading}
          components={{ NoResultsOverlay: NoDataOverlay, NoRowsOverlay: NoDataOverlay }}
        />
      </Box>
    </Page>
  );
};

export default User;
