import React from "react";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Box, Divider, Stack, Typography } from "@mui/material";

const Dropdown = ({ options, buttonElem }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        width="max-content"
        height="max-content"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}>
        {buttonElem}
      </Button>
      <Menu
        open={open}
        id="basic-menu"
        anchorEl={anchorEl}
        width="max-content"
        height="max-content"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {options.length > 0 ? (
          options.map((option, index) => (
            <Box key={index}>
              {option?.sectionBreakAbove && <Divider />}
              <MenuItem
                onClick={() => {
                  const { callback } = option;
                  callback();
                  handleClose();
                }}>
                <Stack flexDirection="row" alignItems="center" justifyContent="left">
                  {option?.leftElem}
                  <Typography>{option.label}</Typography>
                  {option?.rightElem}
                </Stack>
              </MenuItem>
              {option?.sectionBreakBelow && <Divider />}
            </Box>
          ))
        ) : (
          <MenuItem onClick={handleClose}>Nothing To Show</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default Dropdown;
