import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  email: yup.string().email("please enter a valid email").required("email is a required field"),
  password: yup.string().required("password is a required field"),
});

export const recoveryValidationSchema = yup.object().shape({
  email: yup.string().email("please enter a valid email").required("email is a required field"),
});

export const resetPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("new password is a required field")
    .min(8, "new password must be at least 8 character long"),
  rePassword: yup
    .string()
    .required("confirm password is a required field")
    .oneOf([yup.ref("password"), null], "passwords must match"),
});

export const updatePasswordValidationSchema = yup.object().shape({
  password: yup.string().required("current password is a required field"),
  newPassword: yup
    .string()
    .required("new password is a required field")
    .min(8, "new password must be at least 8 character long"),
  rePassword: yup
    .string()
    .required("confirm password is a required field")
    .oneOf([yup.ref("newPassword"), null], "passwords must match"),
});

export const updateProfileValidationSchema = yup.object().shape({
  name: yup.string().required("name is a required field"),
  email: yup.string().email().required("email is a required field"),
});
