import React from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Container,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Email, LinkedIn, Twitter } from "@mui/icons-material";

import Section from "../../widgets/Section";
import FormButton from "../global/FormButton";

import { formResetHandler } from "../../utils/form";
import { contactRsquared } from "../../api/contact";
import { contactValidationSchema } from "../../validation/contact";
import { feedbackActions } from "../../store/reducers/feedbackReducer";
import { RSQ_TWITTER, RSQ_LINKEDIN, RSQ_CONTACT_EMAIL } from "../../constants/links";

const MotionContainer = motion(Container);

const ContactUs = () => {
  const dispatch = useDispatch();

  const resolver = yupResolver(contactValidationSchema);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const res = await contactRsquared(data);
    if (res.data) {
      dispatch(feedbackActions.NOTIFY({ status: "success", message: res.data }));
      reset(formResetHandler(data));
    }
  };

  return (
    <Section id="contact">
      <Typography
        pb="5vh"
        fontWeight="700"
        lineHeight="1.2"
        letterSpacing={2}
        textAlign="center"
        fontSize={{ md: "42px", sm: "36px", xs: "30px" }}>
        Get In Touch
      </Typography>
      <MotionContainer
        maxWidth="lg"
        disableGutters
        sx={{
          borderRadius: 2,
          position: "relative",
        }}>
        <Stack flexWrap="wrap" flexDirection="row" alignItems="stretch" justifyContent="center">
          <Stack
            spacing={2}
            boxSizing="border-box"
            alignItems="flex-start"
            justifyContent="center"
            p={{ lg: 8, md: 4, sm: 6, xs: 4 }}
            width={{ lg: "40%", md: "35%", sm: "100%", xs: "100%" }}
            sx={{
              background: "linear-gradient(187deg, rgba(124,70,221,1) 0%, rgba(93,41,226,1) 100%)",
            }}>
            <Typography fontSize="1.7rem" fontWeight="700">
              Let&apos;s Connect
            </Typography>
            <Typography sx={{ maxWidth: "300px" }}>
              Stay in touch via our social and professional networking media handles.
            </Typography>
            <Stack direction="row" spacing={2}>
              <a href={`mailto:${RSQ_CONTACT_EMAIL}`} target="_blank" rel="noreferrer">
                <IconButton>
                  <Email sx={{ width: "30px", height: "30px", fill: "#fff" }} />
                </IconButton>
              </a>
              <a href={RSQ_TWITTER} target="_blank" rel="noreferrer">
                <IconButton>
                  <Twitter sx={{ width: "30px", height: "30px", fill: "#fff" }} />
                </IconButton>
              </a>
              <a href={RSQ_LINKEDIN} target="_blank" rel="noreferrer">
                <IconButton>
                  <LinkedIn sx={{ width: "30px", height: "30px", fill: "#fff" }} />
                </IconButton>
              </a>
            </Stack>
          </Stack>

          <Stack
            spacing={2}
            height="100%"
            component="form"
            bgcolor="#171717"
            boxSizing="border-box"
            alignItems="flex-start"
            justifyContent="center"
            onSubmit={handleSubmit(onSubmit)}
            p={{ lg: 10, md: 5, sm: 6, xs: 4 }}
            width={{ lg: "60%", md: "65%", sm: "100%", xs: "100%" }}>
            <Typography fontSize="1.7rem" fontWeight="700">
              Have something to say?
            </Typography>
            <FormControl sx={{ width: "100%" }} error={errors?.fullName}>
              <TextField
                label="Full Name"
                error={errors?.fullName}
                {...register("fullName")}
                placeholder="Your full name"
              />
              <FormHelperText sx={{ ml: 0 }}>{errors?.fullName?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "100%" }} error={errors?.email}>
              <TextField
                type="email"
                label="Email"
                error={errors?.email}
                {...register("email")}
                placeholder="Your email"
              />
              <FormHelperText sx={{ ml: 0 }}>{errors?.email?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "100%" }} error={errors?.organization}>
              <TextField
                label="Organization"
                error={errors?.organization}
                {...register("organization")}
                placeholder="Who do you represent?"
              />
              <FormHelperText sx={{ ml: 0 }}>{errors?.organization?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "100%" }} error={errors?.message}>
              <TextField
                rows={4}
                multiline
                label="Message"
                error={errors?.message}
                {...register("message")}
                placeholder="Write something"
              />
              <FormHelperText sx={{ ml: 0 }}>{errors?.message?.message}</FormHelperText>
            </FormControl>
            <FormButton type="submit" isLoading={isSubmitting} fullWidth>
              Let&apos;s Talk
            </FormButton>
          </Stack>
        </Stack>
      </MotionContainer>
    </Section>
  );
};

export default ContactUs;
