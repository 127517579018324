import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Foooter from "../widgets/Foooter";
import Navabar from "../widgets/Navbar";
import FeedbackNotification from "../components/global/FeedbackNotification";

const SiteLayout = () => {
  return (
    <Box>
      <Navabar />
      <Box height="90px" />
      <Outlet />
      <Foooter />
      <FeedbackNotification />
    </Box>
  );
};

export default SiteLayout;
