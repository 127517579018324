export const pages = [
  { label: "Services", anchor: "/#service" },
  { label: "Blog", anchor: "/#blog" },
  { label: "Contact", anchor: "/#contact" },
  { label: "Subscribe", anchor: "/#subscribe" },
];

export const RSQ_CONTACT_EMAIL = "analytics.rsquared@gmail.com";
export const RSQ_TWITTER = "https://twitter.com/RSquaredConsul1";
export const RSQ_LINKEDIN = "https://www.linkedin.com/company/rsquared-consulting";
