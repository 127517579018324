/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Key,
  Settings,
  Visibility,
  VisibilityOff,
  PersonOutline,
  PowerSettingsNew,
} from "@mui/icons-material";
import {
  Box,
  Stack,
  Alert,
  Avatar,
  Button,
  colors,
  TextField,
  Typography,
  IconButton,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import FormModal from "./FormModal";
import Dropdown from "../global/DropDown";
import AlertModal from "../global/AlertModal";
import FormButton from "../global/FormButton";

import {
  updateProfileValidationSchema,
  updatePasswordValidationSchema,
} from "../../validation/auth";
import { authActions } from "../../store/reducers/authReducer";
import { feedbackActions } from "../../store/reducers/feedbackReducer";
import { changeAdminPassword, logout, updateAdminUserProfile } from "../../api/auth";

const AvatarButton = ({ name }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Avatar sx={{ width: "28px", height: "28px" }} />
      <Typography ml={1} fontSize="13px" textTransform="capitalize" color="white">
        {name?.slice(0, 16)}
        {name?.length > 16 ? "..." : ""}
      </Typography>
    </Stack>
  );
};

export const AdminNavbar = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState();
  const [activeForm, setActiveForm] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [alertContetnt, setAlertContent] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const handleResolver = () => {
    switch (activeForm) {
      case "PROFILE":
        return updateProfileValidationSchema;
      case "PASSWORD":
        return updatePasswordValidationSchema;
      default:
        return null;
    }
  };

  const resolver = yupResolver(handleResolver());
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const name = useWatch({ control, name: "name" });
  const email = useWatch({ control, name: "email" });

  const handleProfileUpdate = () => {
    setValue("name", user?.name);
    setValue("email", user?.email);
    setActiveForm("PROFILE");
    setProfileModalOpen(true);
  };

  const handleProfileModalClose = () => {
    setProfileModalOpen(false);
    reset({ name: "", email: "" });
  };

  const detectUpdate = () => {
    let flag = true;
    if (user?.name !== name || user?.email !== email) {
      flag = false;
    }
    return flag;
  };

  const onUpdateSubmit = (data) => {
    setFormData(data);
    setAlertContent("Are you sure you want to update your profile?");
    setAlertOpen(true);
  };

  const updateProfile = async () => {
    setFormLoading(true);
    const { name, email } = formData;
    const response = await updateAdminUserProfile({ name, email }, user?.uid);
    if (response.data) {
      dispatch(authActions.UPDATE_PROFILE(response.data));
      dispatch(feedbackActions.NOTIFY({ status: "success", message: "Your profile is updated" }));
      handleProfileModalClose();
    } else {
      dispatch(
        feedbackActions.NOTIFY({
          status: "error",
          message: response.error || "Failed to update your profile",
        }),
      );
    }
    setFormLoading(false);
  };

  const handleLogout = () => {
    setActiveForm("LOGOUT");
    setAlertContent("Are you sure you want to logout?");
    setAlertOpen(true);
  };

  const logOutUser = async () => {
    const isLoggedOut = await logout();
    if (isLoggedOut) {
      dispatch(authActions.LOGOUT());
      dispatch(feedbackActions.NOTIFY({ status: "info", message: "You have been logged out" }));
    } else {
      dispatch(
        // eslint-disable-next-line quotes
        feedbackActions.NOTIFY({ status: "warning", message: "Sorry couldn't log you out" }),
      );
    }
  };

  const handleChangePassword = () => {
    setSettingsModalOpen(false);
    setActiveForm("PASSWORD");
    setPasswordModalOpen(true);
  };

  const handlePasswordModalClose = () => {
    setPasswordModalOpen(false);
    reset({ password: "", newPassword: "", rePassword: "" });
  };

  const onPasswordSubmit = (data) => {
    setFormData(data);
    setAlertContent("Are you sure you want to update your password?");
    setAlertOpen(true);
  };

  const updatePassword = async () => {
    const { password, newPassword, rePassword } = formData;
    const response = await changeAdminPassword({ password, newPassword, rePassword });
    if (response.data) {
      reset({ password: "", newPassword: "", rePassword: "" });
      dispatch(
        feedbackActions.NOTIFY({
          status: "success",
          message: response.data,
        }),
      );
      setTimeout(() => logOutUser(), 1200);
    }
  };

  const profileMenuOptions = [
    {
      label: "Profile",
      leftElem: <PersonOutline sx={{ height: "18px", width: "18px", marginRight: "5px" }} />,
      callback: handleProfileUpdate,
    },
    {
      label: "Settings",
      leftElem: <Settings sx={{ height: "18px", width: "18px", marginRight: "5px" }} />,
      callback: () => setSettingsModalOpen(true),
    },
    {
      label: "Logout",
      sectionBreakAbove: true,
      leftElem: <PowerSettingsNew sx={{ height: "18px", width: "18px", marginRight: "5px" }} />,
      callback: handleLogout,
    },
  ];

  const handleAlertCallback = () => {
    switch (activeForm) {
      case "LOGOUT":
        return logOutUser;
      case "PROFILE":
        return updateProfile;
      case "PASSWORD":
        return updatePassword;
      default:
        return null;
    }
  };

  return (
    <>
      <AlertModal
        title="Confirm"
        isOpen={alertOpen}
        content={alertContetnt}
        onClose={() => setAlertOpen(false)}
        onResolveClick={handleAlertCallback()}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          noWrap
          variant="h6"
          component="div"
          display={{ xs: "none", sm: "none", md: "block" }}>
          R-Squared Admin Portal
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0 }}>
        <Dropdown options={profileMenuOptions} buttonElem={<AvatarButton name={user?.name} />} />
      </Box>

      {/* PROFILE FORM MODAL */}
      <FormModal
        title="Update Profile"
        isOpen={profileModalOpen}
        onClose={handleProfileModalClose}
        onSubmitCallback={handleSubmit(onUpdateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              label="Name"
              error={errors?.name}
              {...register("name")}
              placeholder="enter your name"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>{errors?.name?.message}</FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Email"
              error={errors?.email}
              {...register("email")}
              placeholder="enter your email"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.email?.message}
            </FormHelperText>
          </Stack>

          <FormButton type="submit" isLoading={formLoading} isDisabled={detectUpdate()}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* SETTINGS MODAL */}
      <FormModal
        title="Settings"
        isOpen={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}>
        <Stack spacing={2}>
          <Alert severity="info">More settings menu options will be available soon!</Alert>
          <Button
            color="warning"
            variant="outlined"
            startIcon={<Key />}
            onClick={handleChangePassword}>
            Change Password
          </Button>
        </Stack>
      </FormModal>

      {/* PASSWORD FORM MODAL */}
      <FormModal
        title="Change Password"
        isOpen={passwordModalOpen}
        onClose={handlePasswordModalClose}
        onSubmitCallback={handleSubmit(onPasswordSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <TextField
              name="password"
              variant="standard"
              id="standard-required"
              label="Current Password *"
              type={showPassword ? "text" : "password"}
              error={errors?.password}
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.password?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              name="newPassword"
              variant="standard"
              label="New Password *"
              id="standard-required"
              type={showNewPassword ? "text" : "password"}
              error={errors?.newPassword}
              {...register("newPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.newPassword?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              name="rePassword"
              variant="standard"
              id="standard-required"
              label="Confirm Password *"
              type={showRePassword ? "text" : "password"}
              error={errors?.rePassword}
              {...register("rePassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRePassword(!showRePassword)}>
                      {showRePassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.rePassword?.message}
            </FormHelperText>
          </Stack>
          <Stack pt={2} flexDirection="row" justifyContent="flex-end" alignItems="center">
            <FormButton type="submit">Change Password</FormButton>
          </Stack>
        </Stack>
      </FormModal>
    </>
  );
};
