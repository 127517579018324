import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Alert,
  Stack,
  Input,
  colors,
  Button,
  TextField,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import { styles } from "../../../constants/styles";
import Page from "../../../components/global/Page";
import FormButton from "../../../components/global/FormButton";
import AlertModal from "../../../components/global/AlertModal";
import FormModal from "../../../components/dashboard/FormModal";
import NoDataOverlay from "../../../components/global/NoDataOverlay";
import { Add, Delete, Edit, PhotoCamera, Visibility, VisibilityOff } from "@mui/icons-material";

import {
  getAllServices,
  createNewService,
  updateServiceImage,
  updateExistingService,
  deleteExistingService,
} from "../../../api/services";
import {
  createServiceValidationSchema,
  updateServiceValidationSchema,
  deleteServiceValidationSchema,
} from "../../../validation/service";
import { serviceAction } from "../../../store/reducers/serviceReducer";
import { feedbackActions } from "../../../store/reducers/feedbackReducer";

const ServiceList = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [formData, setFormData] = useState();
  const [activeForm, setActiveForm] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContetnt, setAlertContent] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const services = useSelector((state) => state.service.services);

  useEffect(() => {
    (async () => {
      setDataLoading(true);
      const res = await getAllServices();
      dispatch(serviceAction.setServices(res));
      setDataLoading(false);
    })();
  }, []);

  const handleResolver = () => {
    switch (activeForm) {
      case "CREATE":
        return createServiceValidationSchema;
      case "UPDATE":
        return updateServiceValidationSchema;
      case "DELETE":
        return deleteServiceValidationSchema;
      default:
        return null;
    }
  };

  const resolver = yupResolver(handleResolver());
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const title = useWatch({ control, name: "title" });
  const password = useWatch({ control, name: "password" });
  const description = useWatch({ control, name: "description" });
  const serviceImage = useWatch({ control, name: "serviceImage" });

  const handleAddModalClose = () => {
    setAddModalOpen(false);
    reset({ title: "", description: "", serviceImage: [] });
  };

  const onCreateSubmit = (data) => {
    setFormData(data);
    setAlertContent(`Are you sure you want to add ${data?.title}`);
    setAlertOpen(true);
  };

  const createService = async () => {
    setFormLoading(true);
    const { serviceImage, title, description } = formData;
    const response = await createNewService({
      title,
      description,
      serviceImage: serviceImage?.[0],
    });
    if (response) {
      dispatch(serviceAction.addService(response));
      dispatch(
        feedbackActions.NOTIFY({ status: "success", message: `${title} added to service list` }),
      );
      handleAddModalClose();
    } else {
      dispatch(feedbackActions.NOTIFY({ status: "error", message: "Failed to add service" }));
    }
    setFormLoading(false);
  };

  const handleRowUpdate = (row) => {
    setRowData(row);
    setValue("title", row.title);
    setValue("description", row.description);
    setActiveForm("UPDATE");
    setUpdateModalOpen(true);
  };

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
    reset({ title: "", description: "", serviceImage: [] });
  };

  const detectUpdate = () => {
    let flag = true;
    if (rowData?.title !== title || rowData?.description !== description || serviceImage?.[0]) {
      flag = false;
    }
    return flag;
  };

  const onUpdateSubmit = (data) => {
    setFormData(data);
    setAlertContent(`Are you sure you want to update ${data?.title}?`);
    setAlertOpen(true);
  };

  const updateService = async () => {
    setFormLoading(true);
    const { title, description, serviceImage } = formData;
    if (serviceImage?.[0]) {
      await updateServiceImage({ serviceImage: serviceImage[0], uid: rowData?.uid });
    }
    const response = await updateExistingService({ title, description }, rowData?.uid);
    if (response) {
      dispatch(serviceAction.updateService(response));
      dispatch(feedbackActions.NOTIFY({ status: "success", message: `${title} updated` }));
      handleUpdateModalClose();
    } else {
      dispatch(feedbackActions.NOTIFY({ status: "error", message: "Failed to update service" }));
    }
    setFormLoading(false);
  };

  const handleRowDelete = (row) => {
    setFormData({ deleteList: [row.uid] });
    setActiveForm("DELETE");
    setAlertContent(
      `Are you sure you want to delete ${row.title}? You can not revert this action!`,
    );
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    reset({ password: "" });
  };

  const onDeleteSubmit = (data) => {
    setFormData({ ...formData, password: data.password });
    setAlertOpen(true);
  };

  const deleteService = async () => {
    setFormLoading(true);
    const response = await deleteExistingService(formData);
    if (response) {
      dispatch(serviceAction.removeService(response));
      dispatch(
        feedbackActions.NOTIFY({
          status: "success",
          message: "Service was removed from the service list",
        }),
      );
      handleDeleteModalClose();
    } else {
      dispatch(feedbackActions.NOTIFY({ status: "error", message: "Failed to delete service" }));
    }
    setFormLoading(false);
  };

  const columns = [
    {
      width: 100,
      field: "image",
      headerName: "Icon",
      renderCell: (params) => (
        <Box width="30px">
          <img src={params.value} width="100%" />
        </Box>
      ),
    },
    {
      minWidth: 140,
      field: "title",
      headerName: "Title",
    },
    {
      flex: 1,
      minWidth: 250,
      field: "description",
      headerName: "Description",
    },
    {
      width: 110,
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton color="success" onClick={() => handleRowUpdate(params.row)}>
            <Edit />
          </IconButton>
          <IconButton color="error" onClick={() => handleRowDelete(params.row)}>
            <Delete />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const handleAlertCallback = () => {
    switch (activeForm) {
      case "CREATE":
        return createService;
      case "UPDATE":
        return updateService;
      case "DELETE":
        return deleteService;
      default:
        return null;
    }
  };

  return (
    <Page>
      {/* ALERT MODAL */}
      <AlertModal
        title="Confirm"
        isOpen={alertOpen}
        content={alertContetnt}
        onClose={() => setAlertOpen(false)}
        onResolveClick={handleAlertCallback()}
      />

      {/* ADD FORM MODAL */}
      <FormModal
        isOpen={addModalOpen}
        title="Add New Service"
        onClose={handleAddModalClose}
        onSubmitCallback={handleSubmit(onCreateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <FormControl error={errors?.serviceImage}>
              <label htmlFor="service-icon-file">
                <Input
                  type="file"
                  id="service-icon-file"
                  sx={{ display: "none" }}
                  {...register("serviceImage")}
                />
                {serviceImage?.[0] ? (
                  <span>
                    <img
                      width="100px"
                      style={{ cursor: "pointer" }}
                      src={URL.createObjectURL(serviceImage[0])}
                    />
                  </span>
                ) : (
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<PhotoCamera />}
                    color={errors?.serviceImage ? "error" : "primary"}>
                    Upload Icon
                  </Button>
                )}
              </label>
            </FormControl>
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.serviceImage?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Title"
              error={errors?.title}
              {...register("title")}
              placeholder="name of your service"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.title?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              rows={4}
              multiline
              label="Description"
              error={errors?.description}
              {...register("description")}
              placeholder="write short description about this service"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.description?.message}
            </FormHelperText>
          </Stack>

          <FormButton type="submit" isLoading={formLoading}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* UPDATE FORM MODAL */}
      <FormModal
        title="Update Service"
        isOpen={updateModalOpen}
        onClose={handleUpdateModalClose}
        onSubmitCallback={handleSubmit(onUpdateSubmit)}>
        <Stack spacing={2}>
          <Stack>
            <FormControl error={errors?.serviceImage} sx={{ width: "fit-content" }}>
              <label htmlFor="service-icon-file">
                <Input
                  type="file"
                  id="service-icon-file"
                  sx={{ display: "none" }}
                  {...register("serviceImage")}
                />
                {!serviceImage?.[0] && rowData?.image ? (
                  <span>
                    <img src={rowData.image} width="100px" style={{ cursor: "pointer" }} />
                  </span>
                ) : serviceImage?.[0] ? (
                  <span>
                    <img
                      width="100px"
                      style={{ cursor: "pointer" }}
                      src={URL.createObjectURL(serviceImage[0])}
                    />
                  </span>
                ) : (
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<PhotoCamera />}
                    color={errors?.serviceImage ? "error" : "primary"}>
                    Upload Icon
                  </Button>
                )}
              </label>
            </FormControl>
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.serviceImage?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              label="Title"
              error={errors?.title}
              {...register("title")}
              placeholder="name of your service"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.title?.message}
            </FormHelperText>
          </Stack>
          <Stack>
            <TextField
              rows={4}
              multiline
              label="Description"
              error={errors?.description}
              {...register("description")}
              placeholder="write short description about this service"
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.description?.message}
            </FormHelperText>
          </Stack>

          <FormButton type="submit" isLoading={formLoading} isDisabled={detectUpdate()}>
            Save
          </FormButton>
        </Stack>
      </FormModal>

      {/* DELETE FORM MODAL */}
      <FormModal
        title="Delete Service"
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        onSubmitCallback={handleSubmit(onDeleteSubmit)}>
        <Stack spacing={2}>
          <Alert severity="warning">Enter your password to confirm this action.</Alert>
          <Stack>
            <TextField
              name="password"
              label="Password *"
              variant="standard"
              autoComplete="current-password"
              id="standard-password-input-required"
              type={password?.length > 0 ? (showPassword ? "text" : "password") : "text"}
              error={errors?.password}
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText sx={{ color: colors.red[500] }}>
              {errors?.password?.message}
            </FormHelperText>
          </Stack>
          <FormButton
            type="submit"
            isLoading={formLoading}
            isDisabled={!password || password?.length < 8}>
            Delete
          </FormButton>
        </Stack>
      </FormModal>

      {/* PARENT COMPONENT */}
      <Stack direction="row" alignItems="center" flexWrap="wrap" justifyContent="space-between">
        <Typography {...styles.text.adminPageHeader}>Services</Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            setActiveForm("CREATE");
            setAddModalOpen(true);
          }}>
          Add New
        </Button>
      </Stack>
      <Box height="400px" my={3}>
        <DataGrid
          rows={services}
          columns={columns}
          loading={dataLoading}
          components={{ NoResultsOverlay: NoDataOverlay, NoRowsOverlay: NoDataOverlay }}
        />
      </Box>
    </Page>
  );
};

export default ServiceList;
