import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const AlertModal = ({
  isOpen,
  onClose,
  onResolveClick,
  title = "Alert",
  rejectText = "No",
  resolveText = "Yes",
  content = "Are you sure you want to execute this action?",
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rejectText}</Button>
        <Button
          onClick={() => {
            onResolveClick();
            onClose();
          }}>
          {resolveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
