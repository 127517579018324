import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ThemeSetup from "./app/ThemeSetup";
import { store, persistor } from "./store";
import "./styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PersistGate persistor={persistor} loading={null}>
    <Provider store={store}>
      <ThemeSetup />
    </Provider>
  </PersistGate>,
);
