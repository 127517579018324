import { createSlice } from "@reduxjs/toolkit";
import { LOGIN, LOGOUT, UPDATE_PROFILE } from "../actions/types";

const initState = {
  isAuth: false,
  token: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    [LOGIN]: (state, action) => {
      state.isAuth = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    [LOGOUT]: (state) => {
      state.isAuth = false;
      state.token = null;
      state.user = null;
    },

    [UPDATE_PROFILE]: (state, action) => {
      state.user = { ...state.user, name: action.payload.name, email: action.payload.email };
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
