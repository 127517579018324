import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Alert, Box, Container, Divider, Skeleton, Stack, Typography } from "@mui/material";

import { fetchBlogBySlug } from "../../api/blog";
import { Circle } from "@mui/icons-material";

const headingLevels = {
  1: { fontSize: 32, fontWeight: 700 },
  2: { fontSize: 28, fontWeight: 700 },
  3: { fontSize: 24, fontWeight: 700 },
  4: { fontSize: 20, fontWeight: 700 },
  5: { fontSize: 16, fontWeight: 700 },
  6: { fontSize: 14, fontWeight: 700 },
};

const Blog = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const blog = await fetchBlogBySlug(slug);
    setPost(blog);
    setLoading(false);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RenderBodyItem = ({ block, index }) => {
    const body = JSON.parse(post.body);
    const blocks = body.blocks;

    switch (block.type) {
      case "paragraph":
        return (
          <Box variant="body1" my={0.75}>
            {parse(block.data.text)}
          </Box>
        );
      case "header":
        return (
          <Typography {...headingLevels[block.data.level]} my={0.75}>
            {block.data.text}
          </Typography>
        );
      case "image":
        return (
          <Stack width="100%" alignItems="center">
            <Stack
              width="100%"
              p={block.data.withBackground ? 2 : 0}
              mt={blocks[index - 1]?.type === "image" ? 0 : 4}
              mb={block.data.caption.replace("<br>", "") ? 0 : 4}
              alignItems={block.data.withBackground ? "center" : "start"}
              border={block.data.withBorder ? "1px solid #E3E3E3" : "none"}
              bgcolor={block.data.withBackground ? "#EDF3F6" : "transparent"}>
              <Box
                component="img"
                maxWidth="100%"
                id={block.data.file.uid}
                src={block.data.file.url}
                width={
                  block.data.stretched
                    ? "100%"
                    : document.getElementById(block.data.file.uid)?.naturalWidth
                }
              />
            </Stack>
            {block.data.caption.replace("<br>", "") && (
              <Typography variant="caption" color="textSecondary" mb={4}>
                {block.data.caption.replace("<br>", "")}
              </Typography>
            )}
          </Stack>
        );
      case "list":
        return (
          <Stack spacing={1} my={0.75} width="100%">
            {block?.data?.items?.map((item, index) => (
              <Stack key={item} direction="row" alignItems="start" spacing={1} pl={1}>
                {block?.data?.style === "unordered" ? (
                  <Circle sx={{ fontSize: 8, mt: 1 }} />
                ) : (
                  <Typography fontSize={16} fontWeight={700}>
                    {index + 1}.
                  </Typography>
                )}
                <Typography fontSize={16} fontWeight={600}>
                  {item.replace("<br>", "")}
                </Typography>
              </Stack>
            ))}
          </Stack>
        );
      case "warning":
        return (
          <Alert severity="warning" sx={{ width: "100%" }}>
            <b>{block.data.title}</b>
            <br />
            {block.data.message}
          </Alert>
        );
      default:
        return (
          <Typography variant="body1" my={0.75}>
            {block.data.text}
          </Typography>
        );
    }
  };

  const RenderBody = () => {
    if (post) {
      const body = JSON.parse(post.body);
      return (
        <Stack spacing={loading ? 1 : 4}>
          {loading ? (
            <Skeleton with="100%" height="450px" />
          ) : (
            <Box width="100%">
              <Box component="img" src={post.featuredImage} width="100%" />
            </Box>
          )}
          <Stack direction="row">
            <Typography variant="body2">
              {loading ? (
                <Skeleton width="200px" />
              ) : (
                `Published on ${dayjs(post.updatedAt).format("DD, MMM, YYYY")}`
              )}
            </Typography>
          </Stack>
          <Typography variant="h4" fontWeight="800">
            {loading ? (
              <>
                <Skeleton />
                <Skeleton width="45%" />
              </>
            ) : (
              post.title
            )}
          </Typography>
          {loading ? (
            <Skeleton width="120px" height="240px" />
          ) : (
            <Stack direction="row" flexWrap="wrap">
              {post.authorImage && (
                <Box width="120px" mr={1}>
                  <Box component="img" src={post.authorImage} width="100%" />
                </Box>
              )}

              <Stack minWidth="340px" flex={1} justifyContent="flex-end">
                {post.author && (
                  <a target="_blank" rel="noreferrer" href={post?.authorProfile}>
                    {post?.author}
                  </a>
                )}
                {post.aboutAuthor && (
                  <Typography fontSize="0.9rem" mt={1}>
                    {post?.aboutAuthor}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
          <Divider />
          <Stack spacing={loading ? 1 : 5}>
            {loading ? (
              <>
                <Skeleton />
                <Skeleton width="75%" />
                <Skeleton width="85%" />
                <Skeleton width="65%" />
                <Skeleton width="45%" />
              </>
            ) : (
              body.blocks.map((block, index) => (
                <RenderBodyItem key={block.id} block={block} index={index} />
              ))
            )}
          </Stack>
        </Stack>
      );
    } else {
      return loading ? (
        <Stack spacing={loading ? 1 : 4}>
          <Skeleton with="100%" height="450px" />

          <Stack direction="row">
            <Typography variant="body2">
              <Skeleton width="200px" />
            </Typography>
          </Stack>
          <Typography variant="h4" fontWeight="800">
            <Skeleton />
            <Skeleton width="45%" />
          </Typography>

          <Skeleton width="120px" height="240px" />

          <Divider />
          <Stack spacing={loading ? 1 : 5}>
            <Skeleton />
            <Skeleton width="75%" />
            <Skeleton width="85%" />
            <Skeleton width="65%" />
            <Skeleton width="45%" />
          </Stack>
        </Stack>
      ) : (
        <Stack width="100%" sx={{ minHeight: "70vh" }} alignItems="center" justifyContent="center">
          <Typography variant="h4" fontWeight="700" textAlign="center">
            Sorry, couldn&apos;t load this post!
          </Typography>
        </Stack>
      );
    }
  };

  return (
    <Box>
      <Helmet>
        <title>{`R-SQUARED | ${post?.title || ""}`}</title>
        <meta name="title" content={`R-SQUARED-${post?.title}`} />
        <meta name="description" content={post?.excerpt} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={location.href} />
        <meta property="og:title" content={post?.title} />
        <meta property="og:description" content={post?.excerpt} />
        <meta property="og:image" content={post?.featuredImage} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={location.href} />
        <meta property="twitter:title" content={post?.title} />
        <meta property="twitter:description" content={post?.excerpt} />
        <meta property="twitter:image" content={post?.featuredImage} />
      </Helmet>
      <Container maxWidth="lg">
        <RenderBody />
      </Container>
    </Box>
  );
};

export default Blog;
